import styled from 'styled-components';
import {Col} from 'antd'
import { mediaIpad, mediaIpadmini, mediaMobile, sizeLessThanDesktop } from '../layoutStyledCom';


export const FooterContainerV3 = styled.div`
  width: 1250px;
  max-width: 100%;
  // display: flex;
  // justify-content: center;
  background-color: #fff;
  color: #000;
  padding: 20px;
  ${ sizeLessThanDesktop.sizeLessThanDesktop`

  ` }
`

export const ImgLogoFooterV2 = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  ${ mediaMobile.Mobile`
    // justify-content: flex-start;
    // width: 50%;
  ` }
  ${ mediaIpadmini.Ipadmini`
    
  ` }
  ${ mediaIpad.Ipad`
    
  ` }
`

export const AboutUSFooterV2 = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  ${ mediaMobile.Mobile`
    justify-content: flex-start;
    width: 100%;
  ` }
  ${ mediaIpadmini.Ipadmini`
    
  ` }
  ${ mediaIpad.Ipad`
    
  ` }
`

export const ContactFooterV2 = styled.div`
  width: 30%;
  ${ mediaMobile.Mobile`
    margin-top: 20px;
    width: 100%;
  ` }
  ${ mediaIpadmini.Ipadmini`
    
  ` }
  ${ mediaIpad.Ipad`
    
  ` }
`

export const FollowFooterV2 = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  ${ mediaMobile.Mobile`
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
  ` }
  ${ mediaIpadmini.Ipadmini`
    
  ` }
  ${ mediaIpad.Ipad`
    
  ` }
`

export const ColFooter = styled(Col)`
  text-align: center;
  ${ sizeLessThanDesktop.sizeLessThanDesktop`
      margin-top: 15px;
  ` }
`