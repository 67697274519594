import React from 'react'
import { Upload, message, Modal } from 'antd'
import { CircularProgress, Button } from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add'
import loadImage from 'blueimp-load-image'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
const dataURLtoBlob = (dataURL) => {
  const BASE64_MARKER = ';base64,'
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const [prefix, suffix] = dataURL.split(',')
    const contentType = prefix.split(':')[1]
    const raw = decodeURIComponent(suffix)
    return new Blob([raw], { type: contentType })
  }
  const [prefix, suffix] = dataURL.split(BASE64_MARKER)
  const contentType = prefix.split(':')[1]
  const raw = window.atob(suffix)
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: contentType })
}

const signatureUri = process.env.REACT_APP_UPLOAD_SIGNATURE_URI
const MAX_DIMENSION = 720
const MAX_THUMBNAIL_DIMENSION = 300
const UPLOAD_FILE_SIZE = 10 * 1024 * 1024

const resizeImg = async (file, { width, height }, quolity = 1) => {
  return new Promise((resolve, reject) => {
    loadImage(file, canvas => {
      if (canvas.type === 'error') {
        return Promise.reject(new Error('Incorrect Image'))
      }
      
      const dataUrl = canvas.toDataURL(file.type, 1)
      const blob = dataURLtoBlob(dataUrl)
      blob.name = file.name
      blob.lastModified = Date.now()
      resolve(blob)
    }, {
      maxWidth: width,
      maxHeight: height,
      contain: true,
      orientation: true,
      canvas: true
    })
  })
}

const gcsUpload = async (file, onProgress) => {
  const res = await fetch(`${signatureUri}?mimetype=${file.type}`, { method: 'POST' })
  const { signedUrl, fileUrl } = await res.json()
    try {
      await axios.put(signedUrl, file, {
        headers: {
          'x-goog-acl': 'public-read',
          'Content-Type': file.type
        }
      })
      return {
        url: fileUrl
      }
  } catch (e) {
    alert('error catch')
    console.error(e)
    Modal.error({
      content: e.message,
    });
  }
}

class ImageUploader extends React.Component {
  state = {
    loading: false
  }
  handleChange = ({ file, fileList, event }) => {
    const { onChange } = this.props
    if (file.status === 'done') {
      onChange(file.response)
      this.setState({ loading: false })
    }
  }
  beforeUpload = file => {
    this.setState({ loading: true })
    if (file.size > UPLOAD_FILE_SIZE) {
      message.error('ขนาดไฟล์รูปที่อัพโหลดใหญ่เกิน 10MB!');
      this.setState({ loading: false })
      return false
    }
    return true
  }
  customRequest = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials
  }) => {
    const UA = navigator.userAgent || navigator.vendor || window.opera;
    const isLINE = function (UA) {
        return (UA.indexOf("Line/") > -1);
    }

    const isAndroid = function (UA) {
      return (UA.indexOf("android") > -1 || UA.indexOf("Android") > -1);
    }

    if (isLINE(UA) || isAndroid(UA)) {

    }
    let originalFile = file
    let thumbnailFile = file
    if (!isAndroid(UA)) {
      // TODO: use axios
      [originalFile, thumbnailFile] = await Promise.all([
        resizeImg(file, { width: MAX_DIMENSION, height: MAX_DIMENSION }),
        resizeImg(file, { width: MAX_THUMBNAIL_DIMENSION, height: MAX_THUMBNAIL_DIMENSION })
      ]).catch(e => {
        Modal.error({
          content: e.message,
          onOk: () =>  window.open("https://www.google.com/intl/th_th/chrome/")
        });
      })
    }
    
    try {
      const [original, thumbnail] = await Promise.all([
        gcsUpload(originalFile, onProgress),
        gcsUpload(thumbnailFile)
      ]).catch(e => {
        Modal.error({
          content: e.message,
          onOk: () =>  window.open("https://www.google.com/intl/th_th/chrome/")
        });
      }) 
      onSuccess({
        original,
        thumbnail
      })
    } catch (e) {
      console.error(e)
      Modal.error({
        content: e.message,
        onOk: () =>  window.open("https://www.google.com/intl/th_th/chrome/")
      });
    }
  }
  render() {
    const { value, loading } = this.props
    const thumbnailUrl = _get(value, 'thumbnail.url')
    const { t } = this.props
    return <Upload
      style={{ width: '100%' }}
      accept="image/*"
      onChange={this.handleChange}
      beforeUpload={this.beforeUpload}
      customRequest={this.customRequest}
      showUploadList={false}
    >
      {value
        ? <img src={thumbnailUrl} alt="logo" style={{ height: 150 }} />
        : <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="default"
            fullWidth={true}
            startIcon={this.state.loading || loading ? <CircularProgress size={24} /> : <AddIcon />}
          >
            {t('submit.upload')}
          </Button>
        </div>
      }
    </Upload>
  }
}

export default withTranslation()(ImageUploader)
