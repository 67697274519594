/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Pagination, Slider } from 'antd'
import { PhotoPagination as $PhotoPagination } from '../../../styled-components/event.js'

const PhotoPagination = ({ pageSize, page, pageCount, onPageChange, itemCount }) => (
  <$PhotoPagination>
    <span className="slider">
      <Slider
        included={false}
        min={1}
        max={pageCount}
        defaultValue={page}
        onAfterChange={onPageChange}
      />
    </span>
    <span className="paging">
      <Pagination
        simple
        defaultCurrent={1}
        defaultPageSize={pageSize}
        current={page}
        total={itemCount}
        onChange={onPageChange}
      />
    </span>
  </$PhotoPagination>
)

export default PhotoPagination