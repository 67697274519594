import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { graphql, compose, withApollo } from 'react-apollo'
import{ Row, Col } from 'antd';
import raceClient from '~/apollo-clients/race'
import sumDistanceByGroupEventQuery from '~/graphql/queries/sumDistanceByGroupEvent.gql'
import withTracker from '~/hocs/withTracker.js';
import _get from 'lodash/get'


class Box extends Component {
  render() {
    const { sumDistanceByGroupEvent } = this.props.data

    return (    
        <Row type="flex" justify="center">
            {sumDistanceByGroupEvent && sumDistanceByGroupEvent.length !==0 ? 
               sumDistanceByGroupEvent && sumDistanceByGroupEvent.map((result, index) =>{
                   const name = _get(result, 'name.en')
                   const distanceUnit = result.distanceUnit
                   const totalDistance = result.totalDistance
                   return <Col xs={24} lg={6} style={{ padding: '10px' }} key={index}>
                      <div style={{ backgroundColor: '#eee', borderRadius: '21px', padding: '20px', marginBottom: '10px'}}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <h1 style={{ color: "#f9a01e", textTransform: 'uppercase' }}>{name}</h1>
                            <h2>{parseFloat(totalDistance.toFixed(2)).toLocaleString()} {distanceUnit}</h2>
                        </div>
                      </div>
                   </Col>
               })
            : ''}
        </Row>
    );  
  }
}

export default 
compose(
  withApollo,
  withRouter,
  graphql(sumDistanceByGroupEventQuery, {
    options: (props) => ({
      variables: { 
        eventId: props.eventId,
      },
      client : raceClient
    })
  }),
  withTracker,
)(Box);
