import React from 'react'
import { Upload, Button, message } from 'antd'
import loadImage from 'blueimp-load-image'
const MAX_DIMENSION = 2400
class ImageUploader extends React.Component {
  state = {
    loading: false
  }
  beforeUpload = async (file, files) => {
    try {
      if (!file) {
        return false
      }
      this.setState({
        loading: true
      })
      loadImage(file, canvas => {
        if (canvas.type === 'error') {
          this.setState({
            loading: false
          })
          return message.error('Incorrect image')
        }
        const imgUrl = canvas.toDataURL('image/jpeg', 0.95)
        const [, mimeType, base64data] = imgUrl.match(/^data:(.+);base64,(.*)$/) || []
        if (!base64data) {
          this.setState({
            loading: false
          })
          return
        }
        this.props.onChange({
          mimeType,
          base64data
        })
        this.setState({
          loading: false
        })
      }, {
        maxWidth: MAX_DIMENSION,
        maxHeight: MAX_DIMENSION,
        contain: true,
        orientation: true,
        canvas: true
      })
      return false
    } catch(e) {
      console.log(`${e}`);
    }
  }

  render () {
    const { value } = this.props
    let imgUrl
    if (value) {
      const { mimeType, base64data } = value
      if (mimeType && base64data) {
        imgUrl = `data:${mimeType};base64,${base64data}`
      } else {
        imgUrl = value
      }
    }

    return <Upload name="file" action="" beforeUpload={this.beforeUpload} showUploadList={false}>
      {value
        ? <img src={imgUrl} alt="logo" style={{ width: 200 }}/>
        : <Button icon="upload" loading={this.state.loading}>Upload</Button>
      }
    </Upload>
  }
}

export default ImageUploader
