import React, { useState } from 'react'
import Chart from "react-apexcharts"
import ApexCharts from 'apexcharts'
import { Spin, Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import dailyTeamDistaceQuery from '../../../../../../graphql/queries/dailyTeamDistance.gql'
import _get from 'lodash/get'
import _minBy from 'lodash/minBy'
import _find from 'lodash/find'
import _indexOf from 'lodash/indexOf'
import _findIndex from 'lodash/findIndex'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'
import raceClient from '~/apollo-clients/race'

const RenderChart = (props) => {
	const { eventId, graphTeamData, chartsMode } = props
	const [chartMode, setChartMode] = useState('default')
	const [clearStatus, setClearStatus] = useState(false)

	const TeamDaily = useQuery( dailyTeamDistaceQuery, 
    { 
      variables: { eventId: eventId },
			client : raceClient,
    }
  )

	if(TeamDaily.loading){
		return <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh' }}>
      <Spin tip="Loading..."/>
    </div> 
	}

	const TeamDailyData = _get(TeamDaily, 'data.TeamDaily')

	if(!TeamDailyData[0]){
		return <div>ไม่พบข้อมูล</div>
	}
	
	const PrepareData = (data) => {
		let sum = 0
		let total = []
		data.forEach((e) => {
			total.push(Number.parseFloat(e + sum).toFixed(2))
			sum = sum + e
		});
		return total
	}

	const getXaxis = () => {
		let startDate = moment(_minBy(TeamDailyData[0].date))
		const nowDate = moment()
		const count = nowDate.diff(startDate, 'days')+1
		let date = []
		date.push(moment(startDate).format('YYYY-MM-DD'))
		const startBreakDate = moment('2021-05-02').format('YYYY-MM-DD')
		const endBreakDate = moment('2021-09-28').format('YYYY-MM-DD')
		const start = moment('2021-04-16').format('YYYY-MM-DD')
		const end = moment('2021-12-26').format('YYYY-MM-DD')

		
		if(eventId === '60459b16e4cedae385b29552'){
			for(let i = 1; i < count; i++){
			
				const d = startDate.add(1, 'days').format('YYYY-MM-DD')
				if(moment(d).isBetween(startBreakDate, endBreakDate) === false && moment(d).isBetween(start, end)){
					date.push(d)
				}
			}

		} else {
			for(let i = 1; i < count; i++){
			
				const d = startDate.add(1, 'days').format('YYYY-MM-DD')
				date.push(d)
			}
		}
		return date

	}

	const getData = (teamData) => {
		const dateAll = getXaxis()
		let data = []
		dateAll.forEach((e,i)=>{
			const valid = _find(teamData.date, function(o){
				return moment(o).format('YYYY-MM-DD') === e
			})
			if(valid){
				const index = _findIndex(teamData.date, function(o){
					return moment(o).format('YYYY-MM-DD') === e
				})
				data.push(Number.parseFloat(teamData.total[index]).toFixed(2))
			} else {
				data.push(0)
			}
		})
		if(chartsMode === 'regTypeTab'){
			return data
		} else {
			let sum = 0
			let total = []
			data.forEach((e) => {
				total.push(Number.parseFloat(e + sum).toFixed(2))
				sum = sum + e
			});
			return total
		}
	}

	const xaxisDate = getXaxis().map((val, index) => index + 1)
	const teamData = TeamDailyData.map((val) => ({name: val.name, data: getData(val)}))
	const seriesPrepare = _orderBy(teamData, function(o){return parseFloat(o.data[o.data.length - 1])}, 'desc')
	let series
	if(chartMode === 'default'){
		series = seriesPrepare.slice(0, 10)
	} else if(chartMode === 'clear'){
		series = seriesPrepare
		if(clearStatus){
			series.forEach((e,i)=>{
				ApexCharts.exec('chart', 'hideSeries', e.name)
			})
			setClearStatus(false)
		}
	}
	else {
		series = seriesPrepare
	}

	const onClickMode = (mode) => {
		if(chartMode === 'clear' && mode === 'all'){
			series.forEach((e,i)=>{
				ApexCharts.exec('chart', 'showSeries', e.name)
			})
		} else if(chartMode === 'clear' && mode === 'default') {
			series.forEach((e,i)=>{
				ApexCharts.exec('chart', 'showSeries', e.name)
			})
		} else if(chartMode === 'all' && mode === 'default'){
			series.forEach((e,i)=>{
				ApexCharts.exec('chart', 'showSeries', e.name)
			})
		}else if(mode === 'clear'){
			setClearStatus(true)
		}

		setChartMode(mode)
	}

	const options = {
		chart: {
			id: 'chart',
			height: 400,
			type: 'line',
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: 'straight',
			width: 4
		},
		markers: {
			size: 2
		},
		grid: {
			show: false
		},
		xaxis: {
			categories: xaxisDate,
			labels: {
				show: true,
			}
		},
		legend: {
			onItemClick: {
				toggleDataSeries: true
			},
		}
	}
	return (
		<div>
			{true? 
				<div className="app">
					<div className="row" style={{display: 'flex', justifyContent: 'center'}}>
					<div className="mixed-chart teamchart">
						<Chart
						  id="chart"
							options={options}
							series={series}
							type="line"
							width="100%"
							height="400"
						/>
						{seriesPrepare.length > 10 && <Button style={{margin: '5px'}} onClick={()=> onClickMode('default')}>top10</Button>}
						{seriesPrepare.length > 10 && <Button style={{margin: '5px'}} onClick={()=> onClickMode('all')}>all</Button>}
						{chartMode === 'all' && <Button style={{margin: '5px'}} onClick={()=> onClickMode('clear')}>clear</Button>}
					</div>
					</div>
				</div>
			
			: 'loading'
			}
		</div>
	)

}
export default RenderChart