import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const clientId = process.env.REACT_APP_CLIENT_ID
const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_RACE_API_URI,
    headers: {
      ...(clientId && { 'X-ThaiRun-Client-ID': clientId })
    }
  }),
  cache: new InMemoryCache(),
})


export default client