import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-hooks'
import RouterApp from './Route/RouterApp.js'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import Popup from '../src/components/pages/finisherwall/event/event.js'
import {Helmet} from "react-helmet";
import moment from 'moment'
import 'moment/locale/th'
import 'antd/dist/antd.css'
import './style.css'
import i18n from './i18n'
moment.locale('th')
const client = new ApolloClient({
    link: new HttpLink({ uri: process.env.REACT_APP_API_URI }),
    cache: new InMemoryCache(),
  })

ReactDOM.render(
  window.location.host === 'run.thai.run' ?
  <Router>
    <Route component={() => {
      window.location.replace('https://vr.thai.run/')
      return null
    }} />
  </Router> :
  <ApolloProvider client={client}>
    <ApolloProviderHooks client={client}>
      <Router>
        <RouterApp i18n={i18n} component={Popup} />
      </Router>
      <Helmet>
        <title>{window.location.hostname.toUpperCase()}</title>
      </Helmet>
    </ApolloProviderHooks>
  </ApolloProvider>
,document.getElementById('root'))
unregisterServiceWorker()