import React, { Component } from "react";
import { Button, Col, Row, Card } from "antd";
import {
  Eventday,
  Eventtext,
  TotalEvent,
  Eventbutton,
  BoximgContainer,
  EventCardWrapper,
} from "../../styled-components/index.js";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import moment from "moment";
import _get from "lodash/get";
import "./css/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

class PinnedEvents extends Component {
  render() {
    const { raceImgMap, pinnedEvents } = this.props;
    return (
      <div>
        <div className="textSectionHeader">
          <span className="textSectionHeader" style={{ fontWeight: "bold" }}>
            Popular
          </span>{" "}
          this week
        </div>
        <Row type="flex" gutter={[15, 15]}>
          {pinnedEvents.map((data, index) => {
            let timeDay = "00";
            let timeDayEnd = "00";
            let timeMonth = "00";
            let timeMonthEnd = "00";
            let thumbnailUrl = "";
            let title = "";
            let detail = <div></div>;
            let timeStart = "";
            let timeEnd = "";
            let timeOpen = "";
            let finisherwall = "";
            if (data.type === "races_event") {
              timeDay = moment(data.startTime).format("DD");
              timeDayEnd = moment(data.settings.virtualRun.period.end).format(
                "DD"
              );
              timeMonth = moment(data.startTime)
                .locale(this.props.i18n.language)
                .format("MMMM");
              timeMonthEnd = moment(data.settings.virtualRun.period.end)
                .locale(this.props.i18n.language)
                .format("MMMM");
              thumbnailUrl = raceImgMap[data.slug];
              title = _get(data, "name.en");
              timeStart = moment(data.settings.virtualRun.period.start).format(
                "YYYY-MM-DD HH:mm"
              );
              timeEnd = moment(data.settings.virtualRun.period.end).format(
                "YYYY-MM-DD HH:mm"
              );
              timeOpen = moment().isBetween(timeStart, timeEnd);
              detail = (
                <TotalEvent>
                  <div className="TEvent">
                    <Eventbutton>
                      <Link to={`/~${data.slug}`}>
                        <Button className="btn-table">FINISHERWALL</Button>
                      </Link>

                      {timeOpen ? (
                        <div>
                          <Link
                            to={{
                              pathname: `/~${data.slug}/submit`,
                              state: { data },
                            }}
                          >
                            <Button className="btn-event">ส่งผลการวิ่ง</Button>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </Eventbutton>
                  </div>
                </TotalEvent>
              );
            } else if (data.type === "vrEvents_event") {
              if (data.published !== false && data.slug !== "Daretorun") {
                timeDay = moment(data.settings.virtualRun.period.start).format(
                  "DD"
                );
                timeDayEnd = moment(data.settings.virtualRun.period.end).format(
                  "DD"
                );
                timeMonth = moment(data.settings.virtualRun.period.start)
                  .locale(this.props.i18n.language)
                  .format("MMMM");
                timeMonthEnd = moment(data.settings.virtualRun.period.end)
                  .locale(this.props.i18n.language)
                  .format("MMMM");
                thumbnailUrl = data.thumbnailUrl;
                title = _get(data, "name.en");
                timeStart = moment(
                  data.settings.virtualRun.period.start
                ).format("YYYY-MM-DD HH:mm");
                timeEnd = moment(data.settings.virtualRun.period.end).format(
                  "YYYY-MM-DD HH:mm"
                );
                timeOpen = moment().isBetween(timeStart, timeEnd);
                finisherwall = data.settings.virtualRun.finisherwall;
                detail = (
                  <TotalEvent>
                    <div className="TEvent">
                      <Eventbutton>
                        {finisherwall ? (
                          <Link to={data.slug}>
                            <Button className="btn-table">FINISHERWALL</Button>
                          </Link>
                        ) : (
                          ""
                        )}
                        {timeOpen ? (
                          <div>
                            <Link
                              to={{
                                pathname: `/${data.slug}/submit`,
                                state: { data },
                              }}
                            >
                              <Button className="btn-event">
                                ส่งผลการวิ่ง
                              </Button>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </Eventbutton>
                    </div>
                  </TotalEvent>
                );
              }
            } else {
              timeDay = moment(data.startDate).format("DD");
              timeDayEnd = moment(data.endDate).format("DD");
              timeMonth = moment(data.startDate).format("MMMM");
              timeMonthEnd = moment(data.endDate).format("MMMM");
              timeStart = moment(data.startDate).format("YYYY-MM-DD HH:mm");
              timeEnd = moment(data.endDate).format("YYYY-MM-DD HH:mm");
              timeOpen = moment().isBetween(timeStart, timeEnd);
              thumbnailUrl = data.thumbnailUrl;
              title = data.title;
              detail = (
                <TotalEvent>
                  <div className="TEvent">
                    <Eventbutton>
                      <Link to={`/~~${data.slug}`}>
                        <Button className="btn-table">FINISHERWALL</Button>
                      </Link>

                      {timeOpen ? (
                        <div>
                          <Link to={`/~~results/${data._id}`}>
                            <Button className="btn-event">ส่งผลการวิ่ง</Button>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </Eventbutton>
                  </div>
                </TotalEvent>
              );
            }
            detail = null;
            return thumbnailUrl ? (
              <Col xs={24} md={8}>
                <EventCardWrapper>
                  <Link key={index} to={`/${data.slug}`}>
                    <Card
                      style={{ backgroundColor: "rgb(250, 250, 250)" }}
                      cover={
                        <div
                          style={{
                            width: "100%",
                            height: 154,
                            overflow: "hidden",
                            borderRadius: 8,
                          }}
                        >
                          <img
                            alt="eventCardImg"
                            src={thumbnailUrl}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      }
                    >
                      <h2>{title}</h2>
                      <p>
                        <span className="faIcon">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                        {timeDay} {timeMonth} ~ {timeDayEnd} {timeMonthEnd}
                      </p>
                    </Card>
                  </Link>
                </EventCardWrapper>
                {detail}
              </Col>
            ) : null;
          })}
        </Row>
      </div>
    );
  }
}
export default PinnedEvents;
