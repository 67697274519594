import React, { useState } from 'react'
import Chart from "react-apexcharts"
import _get from 'lodash/get'




const RenderChart = ({ dataSource, distanceUnit ,rankMode}) => {
	
	const getXaxis = (data) => {
		let xaxis = []
		data.forEach((item, index) => {
			xaxis.push(`${_get(item, 'firstName', '')} ${_get(item, 'lastName', '')}`)
		})
		return xaxis
	}
	const getData = (data) => {

		let series = []
		data.forEach((item, index) => {
			rankMode === 'utpscore' ? series.push(_get(item, 'totalRunningScore', 0).toFixed(2))  : series.push(_get(item, 'totalDistance', 0).toFixed(2))

		})
		return series
	}

	const options = {
		chart: {
			type: 'bar',
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			}
		},
		plotOptions: {
			bar: {
				barHeight: '100%',
				distributed: true,
				horizontal: true,
				dataLabels: {
					position: 'bottom'
				},
			}
		},
		colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
			'#f48024', '#69d2e7'
		],
		dataLabels: {
			enabled: true,
			textAnchor: 'start',
			style: {
				colors: ['#000000']
			},
			formatter: function (val, opt) {
				return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
			},
			offsetX: 0,
		},
		legend: {
			show: false
		},
		stroke: {
			width: 1,
			colors: ['#fff']
		},
		xaxis: {
			categories: getXaxis(dataSource),
			title: {
				text: distanceUnit? `คะแนนรวม (${rankMode === 'utpscore' ? 'points' :distanceUnit})`: `ระยะทาง (${distanceUnit})`,
				align: 'right',
				floating: false
			},
		},
		yaxis: {
			labels: {
				show: false
			}
		},
		tooltip: {
			theme: 'dark',
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function () {
						return ''
					}
				}
			}
		},
		
	};
	

	const series = [{
      data: getData(dataSource)
		}]

  return(
		<div>
				<div className="app">
					<div className="row" style={{display: 'flex', justifyContent: 'center'}}>
					<div className="mixed-chart teamchart">
						<Chart
						  id="chart"
							options={options}
							series={series}
							type="bar"
							width="100%"
							height={`${dataSource.length * 30 + 70}`}
						/>
					</div>
					</div>
				</div>
		</div>
	)
}

export default RenderChart