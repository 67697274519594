import React from 'react';
import { Button, Modal,Icon } from 'antd'

function Footer (){
   const hardReload = () => {
    window.location.reload(true)
  }
  const Version = () => {
    Modal.info({
      title: `Version ${process.env.REACT_APP_WEBAPP_VERSION}`,
      okText: 'กลับ',
      content: (
        <div>
          <Button type="primary" onClick={hardReload} icon="reload"> 
            รีเฟรช
          </Button>
        </div>
      ),
      onOk() {},
    });
  }
    return (
      <div className="footer-text">  
        <div className="footer">        
          Copyright © 2018 บริษัท ไทยดอทรัน จำกัด  <Icon type="info-circle" onClick={Version} theme="filled" style={{ fontSize: '16px' }}/>
        </div>
      </div>
    );  
  }

export default Footer
