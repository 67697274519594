import styled from 'styled-components';
import { mediaIpad, mediaIpadmini, mediaMobile  } from './desktop.js';

export const HeaderContainer = styled.div`
  width: 90%;
`
export const EventContainer = styled.div`
  width: 90%;
  // margin-top: 30px;
  display: flex;
  justify-content: center;
  ${ mediaMobile.Mobile`
    flex-direction: column;
  ` }
  ${ mediaIpadmini.Ipadmini`
    flex-direction: column;
  ` }
  ${ mediaIpad.Ipad`
    flex-direction: column;
  ` }
`

export const EventBalance = styled.div`
  width: 45%;
  margin-top: 30px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  ${ mediaMobile.Mobile`
    margin-bottom: 20px;
  ` }
  ${ mediaIpadmini.Ipadmini`
    width: 100%;
    margin-bottom: 20px;
  ` }
  ${ mediaIpad.Ipad`
    width: 100%;
    margin-bottom: 20px;
  ` }
`

export const EventRunning = styled.div`
  width: 60%;
  margin-top: 30px;
  // margin-right: 50px;
  // margin-bottom: 80px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 40px;
  ${ mediaMobile.Mobile`
    margin-bottom: 10px;
    margin-right: 0px;
  ` }
  ${ mediaIpadmini.Ipadmini`
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  ` }
  ${ mediaIpad.Ipad`
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  ` }
`


export const RunDonation = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  `

export const Chooseevent = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${ mediaIpadmini.Ipadmini`
    display: none;
  ` }
  ${ mediaMobile.Mobile`
    display: none;
  ` }
  ${ mediaIpad.Ipad`
    display: none;
  ` }
`

export const Sliderevent = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  ${ mediaIpadmini.Ipadmini`
    width: 100%;
  ` }
  ${ mediaMobile.Mobile`
    width: 100%;
  ` }
  ${ mediaIpad.Ipad`
    width: 100%;
  ` }
`

export const Tableevent = styled.div`
  // width: 91.67%;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #FAFAFA;
  // margin-top: 20px;
  // display: flex;
  // justify-content: center;
  // align-items: flex-start;
  ${ mediaIpadmini.Ipadmini`
    width: 100%;
  ` }
  ${ mediaMobile.Mobile`
    width: 91.67%;
  ` }
  ${ mediaIpad.Ipad`
    // width: 100%;
  ` }
`

export const Searchevent = styled.div`
  // width: 30%;
  // margin: 0 auto;
  // margin-top: 20px;
  // margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;
  // align-items: flex-start;
  ${ mediaIpad.Ipad`
    display: block;
    width: 50%;
    margin: 0 auto;
  ` }
  ${ mediaIpadmini.Ipadmini`
    display: block;
    width: 50%;
    margin: 0 auto;
  ` }
  ${ mediaMobile.Mobile`
    display: block;
    width: 90%;
    margin: 0 auto;
  ` }
`
export const ButtonSummitevent = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 40%;
  ${ mediaMobile.Mobile`
    width: 90%;
  ` }
`

export const ButtonSummiteventCumulative = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  ${ mediaIpad.Ipad`
    width: 90%;
  ` }
  ${ mediaIpadmini.Ipadmini`
    width: 90%;
  ` }
  ${ mediaMobile.Mobile`
    width: 90%;
    margin-bottom: 10px;
  ` }
`

export const ButtonCumulative = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: initial;
  ${ mediaIpad.Ipad`
    flex-direction: column;
  ` }
  ${ mediaIpadmini.Ipadmini`
    flex-direction: column;
  ` }
  ${ mediaMobile.Mobile`
    flex-direction: column;
  ` }
`

export const FinisherWallCumulative = styled.div`
    background-color: rgb(221, 221, 221);
    // margin: 5px 0px;
    padding-top: 0px;
  ${ mediaMobile.Mobile`
    padding: 0px;
  ` }
`

export const ButtonCumulativeTimeOpen = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 20px;
  ${ mediaIpad.Ipad`
    margin: unset;
  ` }
  ${ mediaIpadmini.Ipadmini`
    margin: unset;
  ` }
  ${ mediaMobile.Mobile`
    margin: unset;
  ` }
`


export const Runamount = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${ mediaIpadmini.Ipadmini`
    height: 140px;
  ` }
  ${ mediaMobile.Mobile`
    height: 70px;
  ` }
  // ${ mediaIpad.Ipad`
  //   width: 100%;
  // ` }
`
export const Rununit = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
`

export const RunDistance =styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
`

export const TitleEvent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  ${ mediaMobile.Mobile`
    margin-top: 10px;
    text-align: center;
  ` }
  ${ mediaIpadmini.Ipadmini`
  ` }
  ${ mediaIpad.Ipad`
  ` }
`
export const TitleEventWall = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  ${ mediaMobile.Mobile`
  width: 100%;
    margin-top: 10px;
    text-align: center;
  ` }
  ${ mediaIpadmini.Ipadmini`
  ` }
  ${ mediaIpad.Ipad`
  ` }
`
export const FinisherWallTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  ${ mediaMobile.Mobile`
    margin-top: 20px;
    text-align: center;
  ` }
  ${ mediaIpadmini.Ipadmini`
  ` }
  ${ mediaIpad.Ipad`
  ` }
`
export const Sliderheight = styled.div`
  width: 100%;
  background-color: #666;
  height: 70px;
  display: flex;
`

export const PhotoPagination = styled.div`
  background-color: black;
  display: flex;
  align-items: center;
  border-radius: 4px;
  .slider {
    text-align: center;
    flex-grow: 1;
    width: 100%;
  }
  .paging {
    width: 300px;
    text-align: center;
    .ant-pagination-simple {
      .ant-pagination-item-link {
        color: white;
        background: none;
      }
      .ant-pagination-simple-pager {
        color: white;
        input {
          background: none;
        }
      }
    }
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
`

export const Iconevent = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${ mediaMobile.Mobile`
  ` }
  ${ mediaIpadmini.Ipadmini`
  ` }
  ${ mediaIpad.Ipad`
  ` }
`

export const Iconeventleft = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${ mediaMobile.Mobile`
  ` }
  ${ mediaIpadmini.Ipadmini`
  ` }
  ${ mediaIpad.Ipad`
  ` }
`

export const Runway = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Runwayevent =styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  margin-top: -30px;
  margin-bottom: 20px;
  ${ mediaMobile.Mobile`
    margin-top: 0px;
  ` }
  ${ mediaIpadmini.Ipadmini`
    margin-top: 0px;
  ` }
  ${ mediaIpad.Ipad`
    margin-top: 10px;
  ` }
`
export const Footer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.800);
  color:#FFF;
  ${ mediaMobile.Mobile`
    width: 100%;
  ` }
  ${ mediaIpadmini.Ipadmini`
    width: 100%;
  ` }
  ${ mediaIpad.Ipad`
    width: 100%;
  ` }
`

export const EcerImgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  ${ mediaMobile.Mobile`
    margin-top: unset;
  ` }
  ${ mediaIpadmini.Ipadmini`
    margin-top: unset;
  ` }
  ${ mediaIpad.Ipad`
    margin-top: unset;
  ` }
`