import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { graphql, compose, withApollo } from 'react-apollo'
import{ Card } from 'antd';
import raceClient from '~/apollo-clients/race'
import sumDistanceByRegionsQuery from '~/graphql/queries/sumDistanceByRegions.gql'
import withTracker from '~/hocs/withTracker.js'

import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import ReactFC from 'react-fusioncharts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)


class regionsChart extends Component {
  render() {
    const { sumDistanceByRegions } = this.props.data

    const chartRegion = {
        chart: {
        caption: "ลำดับรายภูมิภาค",
        yaxisname: `จำนวนผลการสะสม (${this.props.distanceUnit})`,
        plottooltext: `<b>$label</b> จำนวนผลการสะสม <b>$Value</b> ${this.props.distanceUnit}`,
        showvalues: "1",
        numbersuffix: `  ${this.props.distanceUnit}`,
        theme: "fusion",
        palettecolors: '29c3be'
        },
        data: sumDistanceByRegions && sumDistanceByRegions.map(({ regions, totalDistance }) => ({ 
            label: regions.th, 
            value: parseFloat(totalDistance.toFixed(2)).toLocaleString() 
        }))
    }

    return (    
        <div style={{ margin: '20px 0px' }}>
            <Card bordered={false} style={{ height: 500 }}>
                <ReactFC
                    type="column3d"
                    width="100%"
                    height="100%"
                    dataFormat="JSON"
                    dataSource={chartRegion}
                />
            </Card>
        </div>
    );  
  }
}

export default 
compose(
  withApollo,
  withRouter,
  graphql(sumDistanceByRegionsQuery, {
    options: (props) => ({
      variables: { 
        eventId: props.eventId,
        raceId: props.raceId,
        startDate: props.startDate,
        endDate: props.endDate
      },
      client : raceClient
    })
  }),
  withTracker,
)(regionsChart);
