import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { graphql, compose, withApollo } from 'react-apollo'
import{ Card } from 'antd';
import raceClient from '~/apollo-clients/race'
import sumDistanceByProvinceQuery from '~/graphql/queries/sumDistanceByProvince.gql'
import withTracker from '~/hocs/withTracker.js'

import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import ReactFC from 'react-fusioncharts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import _get from 'lodash/get'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)


class provinceChart extends Component {
  render() {
    const { sumDistanceByProvince } = this.props.data

    let regionName = ''
    if ( this.props.region === 'Centre' ) {
        regionName = 'ภาคกลาง'
    }
    if ( this.props.region === 'North' ) {
        regionName = 'ภาคเหนือ'
    }
    if ( this.props.region === 'East' ) {
        regionName = 'ภาคตะวันออก'
    }
    if ( this.props.region === 'North-East' ) {
        regionName = 'ภาคตะวันออกเฉียงเหนือ'
    }
    if ( this.props.region === 'South' ) {
        regionName = 'ภาคใต้'
    }
    if ( this.props.region === 'West' ) {
        regionName = 'ภาคตะวันตก'
    }

    const chartProvince = {
        chart: {
        caption: `ลำดับภาพรวม${regionName}`,
        yaxisname: `จำนวนผลการสะสม (${this.props.distanceUnit})`,
        plottooltext: `<b>$label</b> จำนวนผลการสะสม <b>$Value</b> ${this.props.distanceUnit}`,
        showvalues: "1",
        numbersuffix: `  ${this.props.distanceUnit}`,
        theme: "fusion",
        palettecolors: 'F2726F, 29C3BE, 29C3BE'
        },
        data: sumDistanceByProvince && sumDistanceByProvince.map(({ province, totalDistance }) => (
            { 
                label: _get(province, 'en'), 
                value: parseFloat(totalDistance.toFixed(2)).toLocaleString(), 
            }
        )).slice(0, 5)
    }

    return (    
        <div style={{ margin: '20px 0px' }}>
            <Card bordered={false} style={{ height: 500 }}>
                <ReactFC
                    type="column3d"
                    width="100%"
                    height="100%"
                    dataFormat="JSON"
                    dataSource={chartProvince}
                />
            </Card>
        </div>
    );  
  }
}

export default
compose(
  withApollo,
  withRouter,
  graphql(sumDistanceByProvinceQuery, {
    options: (props) => ({
      variables: { 
        eventId: props.eventId,
        raceId: props.raceId,
        region: props.region,
        startDate: props.startDate,
        endDate: props.endDate
      },
      client : raceClient
    })
  }),
  withTracker,
)(provinceChart);
