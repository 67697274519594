import React from 'react'
import _get from 'lodash/get'
const FacebookBtn = (props) => {
  const link = _get(props, 'link')
  let encodedURL = encodeURI(link);
  return(
    <a className="fbShareBtn" target="_blank" href={`https://facebook.com/sharer/sharer.php?u=${encodedURL}`}>Share on Facebook</a>
  )
}

export default FacebookBtn
