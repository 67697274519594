import React, { Component } from 'react';
import { Row, Col, Spin, Input, Modal, Progress, Button, Menu, Icon, Dropdown} from 'antd';
import { graphql, compose } from 'react-apollo'
import moment from 'moment'
import queryString from 'query-string'
import _get from 'lodash/get'
import PhotoPagination from './PhotoPagination'
import './css/style.css';
import {HeaderContainer, EventContainer, EventBalance, EventRunning, RunDonation, Tableevent} from '../../../styled-components/event.js';
import {ButtonSummitevent, Runamount, Rununit, TitleEvent, Sliderheight, Sliderevent, Iconevent, Iconeventleft, Runway, Runwayevent} from '../../../styled-components/event.js';
import earth from './img/earth.png'
import logo from './img/logo.png'
import eventBySlugQuery from '~/graphql/queries/eventBySlug.gql'
import withTracker from '~/hocs/withTracker';

import Footer from '../../../Footer.js'
const Search = Input.Search;


function warning(data, donateTops, donationBykilo, TopContributors ) {
  Modal.warning({
    title: 'FINISHER WALL (Virtual Run)',
    content: (
      <div className="popup-img">
        <img className="imgpopup" src={data.imageUrl} alt="" />
        <div className="popup-text">
          <p><strong>Distance:</strong> {data.distance.toFixed(2)} Km</p>
        </div>
      </div>
    ),
    onOk() {},
  });
}

class Event extends Component {
  state = {
    isOpen: [],
    runnerRecords: [],
    visible: false,
    page: 1,
    bibSearch: '',
    value: 1000,
    buttonSearch: false
  }

  componentDidMount() {
    this.interval = setInterval(() => {
       const navbar = this.navstk        
      const sticky = navbar&&navbar.offsetTop      

      if(window.pageYOffset > sticky) 
        {
          navbar&&navbar.classList.add("sticky")       
        } 
        else 
        {
          navbar&&navbar.classList.remove("sticky")            
        }
      const { timeStart, timeEnd } = this.state
      const openSubmission = moment(timeStart, "YYYY-MM-DD HH:mm") // set time to open
      const closeSubmission = moment(timeEnd, "YYYY-MM-DD HH:mm") // set time to close 
      const isOpen = moment().isBetween(openSubmission, closeSubmission)
      this.setState({
        isOpen
      })
    }, 1000)
  }
  componentWillReceiveProps(nextProps) {
    const { eventBySlug } = nextProps.data
    const timeStart = moment(eventBySlug.startDate).format('YYYY-MM-DD HH:mm')
    const timeEnd = moment(eventBySlug.endDate).format('YYYY-MM-DD HH:mm')
    this.setState({
      timeStart,
      timeEnd
    })
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }
  
  handleChange = (value) => {
    this.setState({ value });
  }
  handleSearchhandleSearch = (searchBib) => {
    const { pathname, search } = this.props.location
    const searchQuery = queryString.parse(search)
    searchQuery.s = searchBib
    searchQuery.p = undefined
    const searchString = queryString.stringify(searchQuery)
    this.props.history.push({ pathname, search: searchString })
  }
  handlePageChange = (page) => {
    const { pathname, search } = this.props.location
    const searchQuery = queryString.parse(search)
    searchQuery.p = page
    const searchString = queryString.stringify(searchQuery)
    this.props.history.push({ pathname, search: searchString })
  }
  render() {
    if(this.props.data.loading) {
    return <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh' }}>
      <Spin tip="Loading..."/>
      </div>
    }
    const { eventBySlug } = this.props.data
    if(!(eventBySlug)) {
      return <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh' }}>
      <Spin tip="No data"/>
      </div>
    }
    const menu =  (
      <Menu mode="horizontal" 
      theme="dark"
      defaultSelectedKeys={['1']}  
      style={{background:'rgba(0, 0, 0, 0.60)'
      ,marginTop:'0px'
      ,borderRadius:'0px 0px 5px 5px'
      ,fontFamily:'Kanit',}}>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href="/">หน้าหลัก</a>
        </Menu.Item>    
      </Menu>
    );
    const { runnersSearchResults } = eventBySlug
    const { search } = this.props.location
    const { s: searchBib } = queryString.parse(search)
    const { currentPage, perPage, pageCount, itemCount } = runnersSearchResults.pageInfo
    const runnerRecords = _get(eventBySlug, 'runnersSearchResults.items', [])
    const targetedAmount = eventBySlug.targetedAmount
    const amount = eventBySlug.sumDistance
    const percent = Math.floor(amount*100/targetedAmount)
    const sumDistance = eventBySlug.sumDistance
    const timeDay = moment(eventBySlug.startDate).format('DD MMM YYYY')
    const timeDayEnd = moment(eventBySlug.endDate).format('DD MMM YYYY')
    const allDonations  = parseFloat(sumDistance.toFixed(2)).toLocaleString();

    // km
    const totalDistanceslug = eventBySlug.totalDistance
    const totalDistance  = parseFloat(totalDistanceslug.toFixed(2)).toLocaleString();
    return(
      <Row>
        <Col>
            <div className="header1" ref={stk => this.navstk = stk}>          
              <HeaderContainer>         
              <div className="logo">
                    <a href="/" >
                      <img className="logo-top" src={logo}  alt="logo" />
                    </a>                                             
                    <Menu className="nav-test" 
                          mode="horizontal" 
                          theme="light"
                          style={{ lineHeight: '64px'
                          ,background:'transparent' 
                          ,color:'rgb(200, 200, 200)'
                          ,marginTop:'15px',}}>
                    <Menu.Item key="1" className="button-nav"><a className="main" href="/">หน้าหลัก</a></Menu.Item>       
                    </Menu>         
              </div>                      
              </HeaderContainer>
              <div className="nav-test1"> 
                <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                    <Icon type="bars" style={{ fontSize: 30, color: '#fff' }} />
                </Dropdown>
              </div>
            </div>   
        </Col>
        <Col>
          <div className="head-page">
            <h2 className="font-title font-finishrwall">FinisherWall©</h2>  
          </div> 
        </Col> 
        <Col style={{display:'flex', justifyContent:'center'}}>
          <TitleEvent>
            <h3 className="font-title font-slug">{eventBySlug.title}</h3>
            <h4 className="font-title font-virtual "> ระยะเวลาเข้าร่วมกิจกรรม {timeDay} - {timeDayEnd} </h4>
          </TitleEvent>
        </Col>
        <Col style={{display:'flex', justifyContent:'center'}}>      
          <EventContainer>
            <EventRunning style={{ marginBottom: '80px', marginRight: '30px'}} >
              <RunDonation>
                  <span className="font-donationrun">ระยะทางวิ่งทั้งหมด</span>
              </RunDonation>
              <Runamount>
                  <span className="font-amountrun">
                    {totalDistance}
                  </span>                            
              </Runamount>
              <Rununit>                           
                  <span className="font-bahtrun">กิโลเมตร</span>
              </Rununit>
            </EventRunning>
            {eventBySlug.sumDistance > 0 &&
              <EventBalance>
                <RunDonation>
                    <span className="font-donationrun">ยอดเงินบริจาค</span>
                </RunDonation>
                <Runamount>
                    <span className="font-amountrun">
                      {allDonations}
                    </span>                            
                </Runamount>
                <Rununit>                         
                    <span className="font-bahtrun">บาท</span>
                </Rununit>
              </EventBalance>
            }
          </EventContainer> 
        </Col>
        {eventBySlug.targetedAmount ? 
          <Col style={{display:'flex', justifyContent:'center'}}>
            <Runwayevent>
              <Iconeventleft>
                <img src={earth} alt='' style={{maxWidth: '200px', width: '100%'}}/>
              </Iconeventleft>
              <Runway>
                <Progress percent={percent} status="active" showInfo={false}/>
              </Runway>
              <Iconevent>
                <img src={earth} alt='' style={{maxWidth: '200px', width: '100%'}}/>
              </Iconevent>
            </Runwayevent>
          </Col>:""
        }
        <Col>
          
          { !this.state.buttonSearch && <div style={{display:'flex', justifyContent:'center'}}>
            <ButtonSummitevent> 
              <Button type="default" onClick={() => this.setState({buttonSearch: true})} shape="round"  style={{ height: '50px', padding: '0 40px' }}  size="large" block>
                <span style={{ fontSize: '23px' }}>ค้นหาผลการวิ่ง</span>
              </Button>
            </ButtonSummitevent>
          </div>}
          { this.state.buttonSearch && <div style={{display:'flex', justifyContent:'center'}}>
            <ButtonSummitevent>
              <Search id="myInput" defaultValue={searchBib} onSearch={this.handleSearch} placeholder="ค้นหาผลการวิ่งด้วยบิบ..." title="Type in a name" enterButton="ค้นหาผลการวิ่ง" size="large" />
            </ButtonSummitevent>
          </div>}
        </Col>
       <Col>
          <Tableevent>
            <div className="imageGallery">
              {runnerRecords.length!==0 ? runnerRecords.map((runner,index) => runner.thumbnailImageUrl ?
                  <div className="imageItem" onClick={() => warning(runner, eventBySlug.donateTops, eventBySlug.donationBykilo, eventBySlug.maximumDistance)}  key={index} 
                    style={{backgroundImage: `url(${runner.thumbnailImageUrl})` }}/>:
                  <div key={index} style={{display:'none'}}/>
                ):<div style={{color:'#fff', marginBottom:'30px'}}>No data</div>
              }   
            </div>
          </Tableevent>
          <Sliderheight>
            <Sliderevent>
              <PhotoPagination 
                pageSize={perPage}
                page={currentPage}
                pageCount={pageCount}
                onPageChange={this.handlePageChange}
                itemCount={itemCount}
              />
            </Sliderevent>
          </Sliderheight>
        </Col>
        <Footer/>
      </Row>
    );
  }
}

export default compose(
  graphql(eventBySlugQuery, {
    options: (props) => {
      const { search } = props.location
      const searchQuery = queryString.parse(search)
      const { s, p } = searchQuery
      return {
        variables: ({
          bibSearch: s,
          page: p,
          slug: props.match.params.slug //'run-for-love'
        })
      }
    }
  }),
  withTracker,
)(Event)