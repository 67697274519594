import React, { useState, useCallback, useEffect } from 'react'
import Header from '../../../V3/components/header'
import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { 
  Card, 
  CardContent, 
  InputBase,
  IconButton,
  FormControl,
   Select,
  Typography,
  TextField,
   MenuItem,
  Button,
  Modal,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, AlertTitle } from '@material-ui/lab'
import {
  Input,
  Modal as AntModal
} from 'antd'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import './css/home.css'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FloatLabel from '../../components/FloatLabel'
import RunImageUploader from '../../components/RunImageUploader'
import Footer from '../../../V3/components/footer'

import raceClient from '~/apollo-clients/race'
import ocrClient from '~/apollo-clients/ocr'
import moment from 'moment'
import _get from 'lodash/get'
import _sumBy from 'lodash/sumBy'
import _sum from 'lodash/sum'
import { useQuery, useMutation } from '@apollo/react-hooks'
import eventRunFromSlugQuery from '~/graphql/queries/eventRunFromSlug.gql'
import eventTicketItemSearchQuery from '~/graphql/queries/eventTicketItemSearch.gql'
import eventTicketltemQuery from '~/graphql/queries/eventTicketItem.gql'
import distanceFromImageQuery from '~/graphql/queries/distanceFromImage.gql'
import submitraceMutation from '~/graphql/mutations/submitrace.gql'


import styled from 'styled-components'

import Pic_submit from './img/img_submit.svg'

const extraMessages = ({ticketItems, slug}) => {
  const registration = _get(ticketItems, [0, 'participant', 'registration'])
  const registrationId = _get(registration, '_id')
  const registrationType = _get(registration, 'registrationType')
  const deliverOneAddressEnabled = _get(registrationType, 'deliveryOptions.length') > 0
  let selectedDeliveryOption = deliverOneAddressEnabled ? _get(registrationType, 'deliveryOptions').find(o => o._id === _get(registration, 'deliveryOptionId')) : null
  if (_get(registrationType, 'deliveryOptions.length') === 1) {
    selectedDeliveryOption = _get(registrationType, ['deliveryOptions', 0])
  }
  let selectedDeliveryOptionEditabled = false
  const deliveryEditStart = _get(selectedDeliveryOption, 'editStart')
  const deliveryEditEnd = _get(selectedDeliveryOption, 'editEnd')
  if (deliverOneAddressEnabled) {
    selectedDeliveryOptionEditabled = moment().isBetween(moment(deliveryEditStart), moment(deliveryEditEnd))
  }
  const deliverWarningCustomMessage = <div style={{color: 'red'}}>
    {deliveryEditStart && deliveryEditEnd && {
      en: `***Editabled ${moment(deliveryEditStart).format('Do MMMM YYYY')} - ${moment(deliveryEditEnd).format('Do MMMM YYYY')}`,
      zh: `***Editabled ${moment(deliveryEditStart).format('Do MMMM YYYY')} - ${moment(deliveryEditEnd).format('Do MMMM YYYY')}`,
      th: `***ระยะเวลาที่สามารถกรอกหรือแก้ไขที่อยู่จัดส่งได้ ${moment(deliveryEditStart).format('Do MMMM YYYY')} - ${moment(deliveryEditEnd).format('Do MMMM YYYY')}`
    }['th']}
  </div>
  const isProd = true
  const uri = isProd ? 'https://race.thai.run' : 'http://localhost:3002'
  const link = `${uri}/reg/${registrationId}`
  let content = `
  
  <p>การวิ่งออนไลน์คือ วิ่งระยะ 42.195km ภายใน 7 ชั่วโมง เป็นการวิ่งรวดเดียว จากที่ไหนก็ได้ระหว่างวันอาทิตย์ที่ 8 ถึง 15 พฤศจิกายน 2563</p>

  <p>กำหนดส่งผล ระหว่างวันอาทิตย์ที่ 8 พฤศจิกายน 2563 เวลา 9.00น. ถึง 17 พฤศจิกายน 2563 ก่อนเวลา 23.59น.</p>

  <p>กรณีส่งผลการวิ่งแบบออนไลน์ครบถ้วน นักวิ่งจะได้รับ เสื้อPUMA + บิบ + เสื้อCEP + เหรียญรางวัล</p>

  <p>กรณีไม่ได้ส่งผล หรือ ผลที่ส่งไม่ถูกต้องตามเงื่อนไข นักวิ่งจะได้รับเฉพาะ เสื้อPUMA + บิบ</p>

  <p>จัดส่งของให้ทางไปรษณีย์ภายในวันศุกร์ที่ 27 พฤศจิกายน 2563</p>

  <p>สงวนสิทธิ์ให้เฉพาะนักวิ่งที่ทำเรื่องไม่สะดวกเข้าร่วมงานและขอเปลี่ยนเป็นการวิ่งแบบออนไลน์ไว้แล้วเท่านั้น</p>

  `
  content = `
  <p>การวิ่งแบบออนไลน์</p>

  <p>วิ่งที่ไหนก็ได้ ระยะ 21.1กม. ภายในเวลา 3:30ชั่วโมง รวดเดียว ระหว่างวันที่ 20-27 ธันวาคม 2563<p/>
  
  <p>ส่งผล 20-29 ธันวาคม 2563<p/>
  
  <p>กรณีส่งผลการวิ่งครบถ้วน : จะได้รับเสื้อวิ่ง Compressport + บิบ + เหรียญรางวัล<p/>
  
  <p>กรณีไม่ได้ส่งผล หรือ ส่งผลไม่ครบตามกติกา : จะได้รับเฉพาะเสื้อวิ่ง Compressport + บิบเท่านั้น<p/>
  
  <p>จัดส่งของ 11 ม.ค. 2564<p/>
  
  <p>สงวนสิทธิ์ให้เฉพาะนักวิ่งที่ทำเรื่องเปลี่ยนเป็นวิ่งออนไลน์ไว้เท่านั้น<p/>
  
  `
  content = `
  <p data-renderer-start-pos="27" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>BANGSAEN21 &ndash; ONLINE RUNNERส่งผล 20 ธ.ค. 2020 - 5 ม.ค. 2021 23:59 น.</p>

  <p data-renderer-start-pos="102" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>&nbsp;<strong data-renderer-mark="true">การวิ่งแบบออนไลน์</strong></p>

  <p data-renderer-start-pos="122" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>วิ่งที่ไหนก็ได้&nbsp;ให้ครบระยะ&nbsp;21.1กม.&nbsp;ภายในเวลา&nbsp;3:30ชั่วโมง&nbsp;รวดเดียว&nbsp;(ห้ามสะสม)</p>

  <p data-renderer-start-pos="200" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>นับผลการวิ่งที่วิ่งระหว่างวันที่&nbsp;20&nbsp;ธ.ค. 2020 -&nbsp;3&nbsp;ม.ค. 2021&nbsp;เท่านั้น</p>

  <p data-renderer-start-pos="270" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>(ขยายเวลาจากเดิม 27 ธ.ค. 2020)</p>

  <p data-renderer-start-pos="304" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">ส่งผลเข้าระบบ</strong> 20 ธ.ค. 2020 - 5 ม.ค. 2021 ภายใน 23:59 น.</p>

  <p data-renderer-start-pos="361" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>(ขยายเวลาจากเดิม 29 ธ.ค. 2020)</p>

  <p data-renderer-start-pos="395" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">กรณีส่งผลการวิ่งครบถ้วน&nbsp;:</strong> จะได้รับเสื้อวิ่ง Compressport + บิบ + เหรียญรางวัล + Finisher Gift</p>

  <p data-renderer-start-pos="490" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">กรณีไม่ได้ส่งผล&nbsp;หรือ&nbsp;ส่งผลไม่ครบตามกติกา&nbsp;:</strong> จะได้รับเฉพาะเสื้อวิ่ง Compressport + บิบเท่านั้น</p>

  <p data-renderer-start-pos="584" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">จัดส่งของ</strong> 11 ม.ค. 2021</p>

  <p data-renderer-start-pos="608" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>หมายเหตุ;</p>

  <p data-renderer-start-pos="621" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 1. สงวนสิทธิ์ให้เฉพาะนักวิ่งที่ทำเรื่องเปลี่ยนเป็นวิ่งออนไลน์ และ นักวิ่งที่รับบิบแล้วแต่ไม่ได้มาออก start เท่านั้น</p>

  <p data-renderer-start-pos="737" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 2. นักวิ่ง online Runer ยังคงได้สิทธิ์นักวิ่งเก่า</p>

  <p data-renderer-start-pos="787" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 3. นักวิ่งบัตรวีไอพี และ นักวิ่งที่มีสั่งสินค้า pre-order ไว้ ทีมงานจะจัดส่งของทั้งหมดให้พร้อมกัน</p>

          `
  if(['teevr2', '10-2021-runnerdata'].includes(slug)) {
    content = `
    `
  }
  return <div>
    
    {selectedDeliveryOptionEditabled ?
    <Button shape="round" className="btn-affect-cumulative" style={{ height: '40px', padding: '5px 40px', borderRadius: '20px', width: '90%', marginTop: '20px' }}  size="large" block>
        <a rel="noopener noreferrer" target="_blank" href={link}>
          แก้ไขที่อยู่จัดส่ง กดที่นี่
        </a>
    </Button> : (
      deliveryEditEnd &&
      moment().isAfter(deliveryEditEnd) && <div style={{color: 'red'}}>
        <b>NOTE:  หมดเวลากรอกหรือแก้ไขที่อยู่จัดส่ง</b>
      </div>
    )}
    {deliverWarningCustomMessage}
    <div style={{fontSize: 'small'}} dangerouslySetInnerHTML={{ __html: content }} />
  </div>
}

const Search = styled(Input)`
  .ant-input,
  .ant-input-group-addon,
  button
   {
    border-radius: 20px;
  }
`

const BootstrapInput = withStyles(({ spacing }) => ({
  root: {
    'label + &': {
      marginTop: spacing(3),
    },
    maxWidth: '100%',
  },
  input: {
    borderRadius: '20px',
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: '20px',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles(({ spacing }) => ({
  backgroundSubMit: {
    backgroundColor: '#fff',
    maxWidth: '1250px',
    marginTop: '90px',
    width: '100%',
    display: 'flex',
    paddingBottom: '40px',
    paddingTop: '10px'
  },
  root: {
    maxWidth: '80%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: spacing(3),
  },
  paper: {
    maxWidth: '800px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f7f7',
    color: '#b8b8b8',
    borderRadius: spacing(1)
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  input: {
    marginLeft: spacing(1),
    flex: 1,
  },
  pic: {
    width: '80%',
    maxWidth: '400px',
    height: 'auto'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  register: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px'
  },
  formControl: {
    marginTop: spacing(3),
    maxWidth: '800px',
    width: '90%',
  },
  formControlDistance: {
    maxWidth: '800px',
    width: '100%',
  },
  subcard: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
    },
    width: '90%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pos: {
    marginLeft: '5%'
  },
  formButtonSubmit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  ButtonSubmit: {
    fontFamily: 'Metropolis'
  },
  modal: {
    display: 'flex',
    padding: spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 500,
    backgroundColor: '#fff',
    borderRadius: spacing(3),
    boxShadow: '5px',
    padding: spacing(2, 4, 3),
  },
}))

const Submit = (props) => {
  const classes = useStyles();
  const rootRef = React.useRef(null);
  const { t } = props

  const [searchText, setSearchText] = useState(props.match.params.bibNumber)
  const [searchParticipant, setSearchParticipant] = useState('')
  const [ticketItems, setTicketItems] = useState([])
  const [topErrorText, setTopErrorText] = useState('')
  const [selectedTicketItem, setSelectedTicketItem] = useState([])
  const [runner, setRunner] = useState([])
  const [runnerError, setRunnerError] = useState(false)
  const [is0Distance, setIs0Distance] = useState(false)
  const [selectRunner, setSelectRunner] = useState(false)
  const [ticketNumber, setTicketNumber] = useState('')
  const [ticketError, setTicketError] = useState(false)
  const [distance, setDistance] = useState('')
  const [runnerImage , setRunnerlmage] = useState('')
  const [openModel, setOpenModel] = useState(false)
  const [loading, setloading] = useState(false)
  const [runnerImageError, setRunnerImageError] = useState(false)
  const [distanceError , setDistanceError] = useState(false)
  const [sumAlldistance , setSumAlldistance] = useState(0)
  const [remainingDistanceparse , setRemainingDistanceparse] = useState(0)
  const [submitCompleted, setSubmitCompleted] = useState(false)
  const [selectedRace, setSelectedRace] = useState(false)
  const [isMultiRace, setIsMultiRace] = useState(false)
  const [currentLimit, setCurrentLimit] = useState(0)
  const [distanceErrorhours, setdistanceErrorhours] = useState(false)
  const [paceError, setPaceError] = useState(false)
  const [elevationGainEror, setElevationGainError] = useState(false)
  const [time, setTime] = useState(0)
  const [pace , setPace] = useState(0)
  const [elevationGain , setElevationGain] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinute] = useState(0)
  const [confirmDistanceModal, setConfirmDistanceModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [IsImageSubmit, setIsImageSubmit] = useState(true)

  const suffix = (
    <IconButton className={classes.iconButtonbib} onClick={() => setSelectRunner(true)}>
      <EditOutlinedIcon />
    </IconButton>
  )

  
  const eventRunFromSlug = useQuery( eventRunFromSlugQuery, 
    { 
      variables: { slug: props.match.params.slug },
      client : raceClient 
    }
  )

  const eventTicketItemSearch = useQuery( eventTicketItemSearchQuery, 
    {
      client : raceClient,
      skip: true,
    },
  )

  const eventTicketltem = useQuery( eventTicketltemQuery, 
    {
      client : raceClient,
      skip: true,
    },
  )

  const distanceFromImage = useQuery( distanceFromImageQuery, 
    {
      client : ocrClient,
      skip: true,
    },
  )

  const [submitRun] = useMutation( submitraceMutation, 
    {
      client : raceClient,
    },
  )
  const eventId = _get(eventRunFromSlug, 'data.eventRunFromSlug._id')
  const handleSearchParticipant = useCallback(
    async (e) => {
      e.preventDefault()
      setSearchText(searchParticipant)
    },
    [searchParticipant]
  )
  useEffect(() => {
    if (searchText && eventId) {
    eventTicketItemSearch.refetch({ search: searchText, eventId: eventId }).then((result) =>
        {
          if (result.data.eventTicketItemSearch.length === 0 ) {
            setTicketError(true)
          }
          const ticketItems = result.data.eventTicketItemSearch
          setTicketItems(ticketItems)
          if (ticketItems.length === 1) {
            selectRunnerNumber(ticketItems[0].number)
          }
        }
      ).catch(e => {
        console.log('e', e)
        setTopErrorText(`${e}`)
        AntModal.error({
          title: '',
          content: `${e}`,
        })
      })
    }
  }, [searchText, eventId])

  

  const selectRunnerNumber = async (bib, selectedRaceId) => {
    setTicketNumber(bib)
    setDistance('') // Reset value input
    const resultTicketltem = await eventTicketltem.refetch({ number: bib, eventId: eventRunFromSlug.data.eventRunFromSlug._id })
    const event = eventRunFromSlug.data.eventRunFromSlug
    const { distanceMode, limitSubmit, distanceLimit } = event.settings.virtualRun
    const eventTicketItem = resultTicketltem.data.eventTicketItem
    const status = eventTicketItem.participant.registration.status
    const isImage = event.settings.virtualRun.isImageSubmit
    setIsImageSubmit(isImage)
    if (status !== 'paid') {
      setRunnerError(true)
    }
    setSelectRunner(false)
    setRunner(eventTicketItem)
    if (_get(eventTicketItem, 'runs.length') >= limitSubmit  && distanceMode === 'once') {
      setRunnerError(true)
    }else {
      setRunnerError(false)
    }
    if(!isImage){
      setConfirmDistanceModal(true)
    }

    const multiRace = _get(eventTicketItem, 'ticketType.raceIds', []).length > 0
    if (multiRace) {
      setIsMultiRace(true)
    }
    let limit = 300 // Set Default distance  .
    if (selectedRaceId) {
      if (multiRace) {
        const races = _get(eventTicketItem, 'ticketType.races', [])
        const race = races.find(e => e._id === selectedRaceId)
        limit = distanceLimit > 0 && !distanceLimit ? _get(race, 'settings.virtualRun.distanceLimit', 300) : distanceLimit || 300
        setSelectedRace(race)
      } else {
        const race = _get(eventTicketItem, 'ticketType.race', [])
        limit = distanceLimit > 0 && !distanceLimit ? _get(race, 'settings.virtualRun.distanceLimit', 300) : distanceLimit || 300
        setSelectedRace(race)
        setSelectedTicketItem(eventTicketItem)
        
      }
    } else {
      if (!multiRace) {
        const race = _get(eventTicketItem, 'ticketType.race', {})
        limit = distanceLimit > 0 && !distanceLimit ? _get(race, 'settings.virtualRun.distanceLimit', 300) : distanceLimit || 300
        setSelectedTicketItem(eventTicketItem)
        setSelectedRace(race)
      }
    }
    setCurrentLimit(limit)
    const distanceRace = eventTicketItem.ticketType.race.distance 
    const races = eventTicketItem.ticketType.races 
    const distanceRaces = selectedRace.distance
    const distance = races.length === 0 ? distanceRace : distanceRaces
    if (distance <= 0) {
      setIs0Distance(true)
    }
    setSelectRunner(false)
  }

  const handleChangeSelect = (event) => {
    const { bib, raceId } = event.target.value
    selectRunnerNumber(bib, raceId)
  }

  const handleChangeImgOcr = async ({img, isFixedResult, isOCREnabled}) => {
    const images = _get(img, 'original.url')
    if(!images) {
      AntModal.error({
        content: 'undefined original.url',
      })
      return setloading(false)
    }
    if(isFixedResult){
      setDistance(1)
      setRunnerlmage(img)
    } else {
      setloading(true)
      const result = await distanceFromImage.refetch({ imageUri: images })
      if(!isOCREnabled) { // disabled OCR
        setDistance("")
        setEdit(true)
      }else
      if (result.data.distanceFromImage !== null ) {
        if (result.data.distanceFromImage > currentLimit) {
          setDistanceError(true)
        }
        setDistance(result.data.distanceFromImage)
        setloading(false)
      }else
      if(result.data.distanceFromImage === null) {
        setEdit(true)
      }
      setRunnerImageError(false)
      setRunnerlmage(img)
      setConfirmDistanceModal(true)
      setloading(false)
    }
  }

  const handleSummitIsimage = async () => {
    if(isFixedResult){
      setDistance(1)
      setRunnerlmage({})
    } else {
      setloading(true)
      setDistance("")
      setEdit(true)
      setRunnerImageError(false)
      setRunnerlmage({})
      setConfirmDistanceModal(true)
      setloading(false)
    }
  }

  const handleChangeEditBtn = () => {
    if(!edit){
      setEdit(true)
    } else{
      window.location.href=`/${props.match.params.slug}/submit`
    }
  }

  const handleChangeTextFieldNumber = (e) => {
    const distancerp = e.target.value
    if (selectedRace.distanceUnit === 'hours') {
      const val = distancerp.toString().match(new RegExp(/^[0-9]*$/)) // ไม่เกิน 300 
      if (!val) {
        setdistanceErrorhours(true)
      } else {
        setdistanceErrorhours(false)
      }
    }
    setDistance(distancerp)
    if (isNaN(distancerp) || distancerp > currentLimit || distancerp < 0) {
      setDistanceError(true)
    } else {
      setDistanceError(false)
    }
  }

  const handleChangePace = (e) => {
    let pacerp = ''
    pacerp = e.target.value
    pacerp = pacerp.replace(':', '.')
    if(!Number(pacerp)){
      setPaceError(true)
    } else {
      setPaceError(false)
    }
    setPace(Number(pacerp))
  }
  const handleChangeElevationGain = (e) => {
    const elevationGainrp = e.target.value
    if(!Number(elevationGainrp) && elevationGainrp !== '0' || elevationGainrp < 0){
      setElevationGainError(true)
    } else {
      setElevationGainError(false)
    }
    setElevationGain(Number(elevationGainrp))
  }

  const handleChangeTextFieldHours = (e) => {
    const hour = e.target.value
    const seconds = Number(hour * 3600)
    setHours(seconds)
    const timetotal = seconds + minutes
    setTime(timetotal)
  }

  const handleChangeTextFieldMinutes = (e) => {
    const minute = e.target.value
    const seconds = Number(minute * 60)
    setMinute(seconds)
    const timetotal = seconds + hours
    setTime(timetotal)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setloading(true)
    try {
      const { _id, participant,number } = runner
      const participantId = participant._id
      const ticketItemId = _id
      const eventId = eventRunFromSlug.data.eventRunFromSlug._id
      const { testMode } = eventRunFromSlug.data.eventRunFromSlug
      const source = 'run'

      const record = {
        number,
        distance,
        eventId,
        testMode,
        participantId,
        raceId: selectedRace._id,
        ticketItemId,
        runnerImage,
        source,
        time,
        pace,
        elevationGain
      }
      if (runnerImage === '' && IsImageSubmit) {
        setRunnerImageError(true)
        setloading(false)
        return
      }
      const { data } = await submitRun({ variables: { record } })
      if (data) {
        const { raceId } = data.submitRun.record
        setOpenModel(true)
        setloading(false)
        const distanceRecord = data.submitRun.record.distance
        const runs = runner.runs.filter(e => e.raceId === raceId)
        const sumDistanceRunner = _sumBy(runs, 'distance')
        const sumAlldistance = _sum([sumDistanceRunner,distanceRecord])
        setSumAlldistance(sumAlldistance)
        const remaining = selectedRace.distance
        const Distanceparse = remaining - sumAlldistance
        const remainingDistanceparse = parseFloat(Distanceparse.toFixed(2)).toLocaleString()
        setRemainingDistanceparse(remainingDistanceparse)

        if ( parseFloat(Distanceparse.toFixed(2)) <= 0 || distanceMode === "once" ) {
          setSubmitCompleted(true)
        }
      } else {
        alert('Incorrect Data')
      }
    } catch (err) {
      const errorMessage = _get(err, ['graphQLErrors', 0, 'message'])
      if(errorMessage === 'DUPLICATE_REQUEST') {
        setOpenModel(true)
        setloading(false)
        setSubmitCompleted(true)
      } else {
        console.log(errorMessage)
        console.error(err)
        alert(err)
        setloading(false)
      }
    }
  }

  if (eventRunFromSlug.loading){
    return 'loading...'
  }
  const event = eventRunFromSlug.data.eventRunFromSlug
  const { distanceMode } = event.settings.virtualRun
  const { virtualRunMessage } = event.settings.virtualRun
  const timeStart = moment(event.settings.virtualRun.period.start).format('YYYY-MM-DD HH:mm')
  const timeEnd = moment(event.settings.virtualRun.period.end).format('YYYY-MM-DD HH:mm')
  const dateStart = moment(event.settings.virtualRun.period.start).locale(props.i18n.language).format('DD MMMM')
  const dateEnd = moment(event.settings.virtualRun.period.end).locale(props.i18n.language).format('DD MMMM')
  const isAfter = moment().isAfter(timeEnd)
  const isOpen = moment().isBetween(timeStart, timeEnd)
  const isBefore = moment().isBefore(timeStart)
  const name = event.name.th
  let sumDistance = 0
  const { isTime,isPace,isElevationGain } = event.settings.virtualRun
  const isAutoApproved = event.settings.virtualRun.isAutoApproved
  const isOCREnabled = event.settings.virtualRun.isOCREnabled
  const rankMode = event.settings.virtualRun.rankMode

  
  if(!Array.isArray(runner)){
    const runs = runner.runs.filter(e => e.raceId === selectedRace._id)
    sumDistance = _sumBy(runs, 'distance')
    if(!isAutoApproved){
      const runApproved = runs.filter(run => run.status === 'approved' && run.checked !== null)
      sumDistance= _sumBy(runApproved, 'distance')
    }
  }

  const UA = navigator.userAgent || navigator.vendor || window.opera;
  const VersionModal = () => {
    AntModal.info({
      title: ``,
      okText: 'กลับ',
      content: (
        <div>
          {UA}
        </div>
      ),
      onOk() {},
    })
  }

  const sumDistancetoFixed = parseFloat(sumDistance.toFixed(2)).toLocaleString();
  const placeholderSearch = event.slug === 'runforchange' ?  'รหัสพนักงาน': event.slug === 'ntt-runforlife2020' ? 'ใส่เฉพาะตัวเลข โดยไม่ต้อง NTT นำหน้า' : t('submit.search')
  const img = event.thumbnailUrl
  const {noEcer, ecerV2} = event.settings.virtualRun
  const slug = _get(selectedRace, 'slug') || props.match.params.slug
  const isLineLogin = _get(event, 'isLineLogin')
  const isExtraMessages = ['teevr22', 'BS21-2020-Onlinerunner', '10-2021-runnerdata'].includes(props.match.params.slug)
  /////for mice online or extra event//////
  const extraSubmitConditions = [{
    start: moment('2021-01-23T00:00:00.00+0700'),
    end: moment('2021-01-31T23:59:00.00+0700'),
    slugs: ['teevr22', '10-2021-runnerdata']
  }]
  const isExtraSlugCond = extraSubmitConditions.find(({slugs}) => slugs.includes(props.match.params.slug))
  const isExtraSubmitCond = extraSubmitConditions
    .find(({slugs, start, end}) => (slugs.includes(props.match.params.slug) &&
      moment().isBetween(start, end)))
  /////end for mice online//////
  const selectedTicketItemInit = _get(selectedTicketItem, 'length') === 0 ?
    ticketItems[0] :
    selectedTicketItem || ticketItems[0]

  const isFixedResult = _get(selectedRace, 'isFixedResult')
  let submitModalConten = <div>
    <h2 id="server-modal-title" style={{ marginBottom: '20px' }}>{t('submit.resive')}</h2>
    <p id="server-modal-description" style={{ display: 'flex', alignItems:'center', color: '#b8b8b8' }} >
      <FiberManualRecordIcon style={{ color: '#b8b8b8' }}/>{parseFloat(sumAlldistance.toFixed(2)).toLocaleString()} {selectedRace.distanceUnit} {t('submit.nowaccumulated')} 
    </p>
    <p id="server-modal-description" style={{ marginLeft: '10px', color: '#b8b8b8' }}> | </p>
    <p id="server-modal-description" style={{ display: 'flex', alignItems:'center', color: '#b8b8b8' }}>
      <FiberManualRecordIcon style={{ color: '#f9a01e'}}/> {remainingDistanceparse > 0 ? remainingDistanceparse : 0}  {selectedRace.distanceUnit} {t('submit.tocomplete')}
    </p>
  </div>
  if(!isAutoApproved){
    submitModalConten = <div>
      <h2 id="server-modal-title" style={{ marginBottom: '20px' }}>รอการตรวจสอบจากเจ้าหน้าที่</h2>
      <h5 style={{ marginBottom: '5px' }}>(ผลการตรวจสอบจะส่งทาง email หรือ หน้า finisherwall)</h5>
    </div>
  }
  else if(isFixedResult) {
    submitModalConten = <div>
    <h2 id="server-modal-title" style={{ marginBottom: '20px' }}>{t('submit.resive')}</h2>
    <p id="server-modal-description" style={{ display: 'flex', alignItems:'center', color: '#b8b8b8' }} >
      <FiberManualRecordIcon style={{ color: '#b8b8b8' }}/> {parseFloat(sumAlldistance.toFixed(2)).toLocaleString()} {selectedRace.distanceUnit} {t('submit.nowaccumulated')}
    </p>
  </div>
  } else if(submitCompleted) {
    submitModalConten = <div style={{ textAlign: 'center' }} >
    <h2 id="server-modal-title" style={{ marginBottom: '20px' }}>{t('submit.congrat')}</h2>
    {!isExtraMessages && <p id="server-modal-description">{t('submit.complete')}</p>}
    {(!noEcer) ? <p>{t('submit.ecer')}
    {! ecerV2 && <a target="_blank" alt={`${ticketNumber}`}  href={`https://ecer.thai.run/${slug}/${ticketNumber}`}>{t('submit.ecerload')}</a>}
    </p>:""}
  </div>
  }

  const RunningScoreCalculator = (distance, pace, elevationGain) => {
    // console.log(typeof pace);
    let runningValue = 0
    if(!Number(elevationGain)){
      elevationGain = 0
    }
    if(!pace || pace === 0){
      runningValue = 0
    }
    else if(pace >= 10) {
      runningValue = 1
    } else if(pace >= 8){
      runningValue = 1.5
    } else if(pace >= 6){
      runningValue = 1.75
    } else {
      runningValue = 2
    }
    const result = (distance * runningValue) + (elevationGain * 0.02)
    return result
  }

  return(
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Header/>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' ,minHeight: '95vh'}} >
        <div className={classes.backgroundSubMit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} className={classes.imgContainer}>
                <img src={Pic_submit} alt="pic" className={classes.pic} />
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                { isBefore &&  <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'80vh' }}>
                  <p className="font-close">"ยังไม่เปิดส่งผล"</p>
                </div>
                }
                { isOpen &&
                  <Card className={classes.root} variant="outlined">
                    <CardContent style={{ width:'100%' }}>
                      {ticketItems.length === 0 ?
                        <form onSubmit={handleSearchParticipant}>
                          <div className={classes.cardContent}>
                            {!isExtraMessages && <p>{t('submit.submit')}</p>}
                            <h1>{name}</h1>
                            {isExtraSlugCond ?
                              <p>{`${moment(isExtraSlugCond.start).format('DD MMMM')} - ${moment(isExtraSlugCond.end).format('DD MMMM')}`}</p> :
                              <p>{`${dateStart} - ${dateEnd}`}</p>}
                              <p style={{color: 'red'}}>{topErrorText}</p>
                            <FloatLabel label={placeholderSearch} value={searchParticipant}>
                              <Search 
                                className="search-box"
                                id="myInput" 
                              
                                value= {searchParticipant}
                                size="large"
                                onChange= {e => setSearchParticipant(e.target.value)}
                                required
                                style={{ 
                                  width: '90%', 
                                  padding: '0px 0px 0px 20px',
                                  borderRadius: '20px'
                                }} 
                              />

                            </FloatLabel>
                            
                            <Button type="default" shape="round" className="btn-affect-cumulative" style={{ height: '40px', padding: '5px 40px', borderRadius: '20px', width: '90%', marginTop: '20px' }}  size="large" block>
                              <span style={{ fontSize: '18px' }}>{t('submit.btnSearch')}</span>
                            </Button>
                            {!isExtraMessages && !isLineLogin && <div className={classes.register}>
                              <p>{t('submit.register')}</p>
                              <a target="_blank" className="btn-register" href={`https://race.thai.run/${event.slug}`}>{t('submit.registerClick')}</a>
                            </div>}
                            
                            
                          </div>
                          
                          {ticketError && 
                            <Alert severity="error" className={classes.ticketError}>
                              <AlertTitle>Error</AlertTitle>
                              ไม่พบข้อมูลของท่าน กรุณาตรวจสอบข้อมูล และกรอกข้อมูลใหม่อีกครั้ง!
                            </Alert>
                          }
                        </form>:
                        <div className={classes.cardContent}>
                          <p>{t('submit.submit')}</p>
                          <h1>{name}</h1>
                          {isExtraSlugCond ?
                              <p>{`${moment(isExtraSlugCond.start).format('DD MMMM')} - ${moment(isExtraSlugCond.end).format('DD MMMM')}`}</p> :
                              <p>{`${dateStart} - ${dateEnd}`}</p>}
                          <FloatLabel label={placeholderSearch} value={searchText}>
                            <Search 
                              className="search-box"
                              value= {searchText}
                              size="large"
                              disabled
                              style={{ 
                                padding: '0px 0px 0px 20px',
                                borderRadius: '20px'
                              }} 
                            />
                          </FloatLabel>
                          {runner.length !== 0 ?
                            <FloatLabel label={`${t('submit.name')}`} value={`${_get(ticketItems[0], 'participant.profile.firstName')} ${_get(ticketItems[0], 'participant.profile.lastName')}`}>
                              <Search 
                                className="search-box"
                                value= {`${_get(selectedTicketItemInit, 'participant.profile.firstName')} ${_get(selectedTicketItemInit, 'participant.profile.lastName')}`}
                                size="large"
                                disabled
                                style={{ 
                                  padding: '0px 0px 0px 20px',
                                  borderRadius: '20px'
                                }} 
                              />
                            </FloatLabel>:''
                          }
                          {runner.length === 0 || selectRunner || !selectedRace ?
                            <div className={classes.subcard}>
                              <FormControl className={classes.formControl}>
                                <FloatLabel label={t('submit.race')}>
                                  <Select
                                      labelId="select-race"
                                      className={classes.selectEmpty}
                                      onChange={handleChangeSelect}
                                      required
                                      input={<BootstrapInput />}
                                    > 
                                      {ticketItems.map(ticket => {
                                        if (isMultiRace) {
                                          const { races } = ticket.ticketType
                                          return races.map(({ _id, name }) => {
                                            return <MenuItem key={_id} value={{bib: ticket.number, raceId: _id}}>
                                              {ticket.participant.profile.firstName} {name.th}
                                            </MenuItem>
                                          })
                                        }
                                        return <MenuItem 
                                          key={ticket.ticketType.race._id} 
                                          value={{bib: ticket.number, raceId: ticket.ticketType.race._id}}
                                        >
                                          {ticket.participant.profile.firstName} {ticket.ticketType.race.name.th}
                                        </MenuItem>
                                        })
                                      }
                                    </Select>
                                  </FloatLabel>
                              </FormControl>
                              {runnerError && 
                                <div>
                                  {distanceMode === "once" && <Alert severity="success" className={classes.ticketError}>
                                    <AlertTitle>success</AlertTitle>
                                    คุณส่งผลครบแล้ว
                                    </Alert>
                                  } 
                                  {distanceMode !== "once" && <Alert severity="error" className={classes.ticketError}>
                                    <AlertTitle>Error</AlertTitle>
                                    ไม่สามารถส่งผลได้ กรุณาติดต่อเจ้าหน้าที่ (admin@thai.run)
                                  </Alert>
                                  }
                                </div>
                              }
                            </div>:
                            <div className={classes.subcard}>
                              <FloatLabel label={t('submit.race')} value={selectedRace.name.th}>
                                <Search 
                                  className="search-box"
                                  value={selectedRace.name.th}
                                  size="large"
                                  disabled
                                  style={{ 
                                    width: '90%', 
                                    padding: '0px 0px',
                                    borderRadius: '20px'
                                  }}
                                  suffix={suffix}
                                />
                              </FloatLabel>

                              {runnerError && 
                                <div>
                                  {distanceMode === "once" && <Alert severity="success" className={classes.ticketError}>
                                    <AlertTitle>success</AlertTitle>
                                    คุณส่งผลครบแล้ว
                                    </Alert>
                                  }
                                 
                                  {distanceMode !== "once" && <Alert severity="error" className={classes.ticketError}>
                                    <AlertTitle>Error</AlertTitle>
                                    ไม่สามารถส่งผลได้ กรุณาติดต่อเจ้าหน้าที่ (admin@thai.run)
                                  </Alert>
                                  }
                                </div>
                              }
                              {is0Distance && 
                                <div>
                                  {distanceMode !== "once" && <Alert severity="error" className={classes.ticketError}>
                                    <AlertTitle>Error</AlertTitle>
                                    คุณสมัครประเภทตั๋วที่ส่งผลไม่ได้
                                  </Alert>
                                  }
                                </div>
                              }
                            </div>

                          }
                        </div>
                      }
                      <div className="infoBS">
                        <FontAwesomeIcon
                          onClick={VersionModal}
                          icon={faInfoCircle} color="#0089e3"/>
                      </div>
                      {!runnerError && ticketItems.length !== 0 && runner.length !== 0 && selectedRace && distanceMode === 'cumulative' && selectedRace.distance !== 0 &&
                        <div>
                          <Typography className={classes.pos} color="textSecondary">
                          {t('submit.accumulated')} {(distanceMode !== "recent") && `${sumDistancetoFixed} ${selectedRace.distanceUnit}`} 
                          </Typography>
                        </div>
                      }
                      {runnerError && distanceMode === "once" && <div className="makeStyles-cardContent-4">
                        {isExtraMessages && <FormControl className={classes.formControl}>
                          {extraMessages({ticketItems, slug: props.match.params.slug})}
                        </FormControl>}
                      </div>}
                      <form onSubmit={handleSubmit}>
                        {!runnerError && ticketItems.length !== 0 && runner && selectedRace && IsImageSubmit &&
                          <div className="makeStyles-cardContent-4">
                            {isExtraSubmitCond && <FormControl className={classes.formControl}>
                              <RunImageUploader
                                onChange={img => handleChangeImgOcr({img, isFixedResult, isOCREnabled}) }
                                value={runnerImage}
                                loading={loading}
                              />
                            </FormControl>}
                            {isExtraMessages && <FormControl className={classes.formControl}>
                              {extraMessages({ticketItems, slug: props.match.params.slug})}
                            </FormControl>}
                            {!isExtraMessages && selectedRace.distance !== 0 && <FormControl className={classes.formControl}>
                              <RunImageUploader
                                onChange={img => handleChangeImgOcr({img, isFixedResult, isOCREnabled}) }
                                value={runnerImage}
                                loading={loading}
                              />
                            </FormControl>}
                            {runnerImageError && 
                              <Alert severity="error" className={classes.ticketError}>
                                <AlertTitle>Error</AlertTitle>
                                กรุณาอัพโหลดรูปภาพ
                              </Alert>
                            }
                          </div>
                        }
                        {
                          !runnerError && ticketItems.length !== 0 && runner && selectedRace && !IsImageSubmit && selectedRace.distance !== 0 &&
                          <div>
                            <Button
                              variant="contained"
                              color="default"
                              fullWidth={true}
                              onClick={() => handleSummitIsimage()}
                            >
                              ส่งผล
                            </Button>
                          </div>
                        }
                        {
                          !runnerError && ticketItems.length !== 0 && runner.length !== 0 && runnerImage && confirmDistanceModal &&
                          <Modal
                            disablePortal
                            disableEnforceFocus
                            disableAutoFocus
                            open
                            aria-labelledby="server-modal-title"
                            aria-describedby="server-modal-description"
                            className={classes.modal}
                            container={() => rootRef.current}
                          >
                            <div className={classes.paperModel}>
                              <p style={{fontFamily: 'Metropolis', fontSize: '16px'}}>
                                {distance > 0 && isOCREnabled ?
                                  'ระบบอ่านค่าอัตโนมัติได้' :
                                  'ผลที่ต้องการสะสมในครั้งนี้คือ'
                                  }
                              </p>
                              <p>
                                <Typography variant="h3" style={{fontFamily: 'Metropolis'}}>
                                  {/* {distance} {selectedRace.distanceUnit} */}
                                  {rankMode === 'utpscore'? `${RunningScoreCalculator(distance, pace, elevationGain).toFixed(2)} points` : `${distance} ${selectedRace.distanceUnit}`}
                                </Typography>
                              </p>
                              {distance > 0 && isOCREnabled && <p style={{fontFamily: 'Metropolis', fontSize: '16px'}}>
                                ถูกต้องหรือไม่
                              </p>}
                              {edit && <FormControl className={classes.formControlDistance}>
                                <FloatLabel label={`${t('submit.distance')} (${selectedRace.distanceUnit})`} value={distance}>
                                  <Search 
                                    className="search-box"
                                    value={distance}
                                    size="large"
                                    required
                                    style={{ 
                                      width: '90%', 
                                    }}
                                    onChange={e => handleChangeTextFieldNumber(e)}
                                  />
                                </FloatLabel>
                              </FormControl>}
                              {isPace === true && <FormControl className={classes.formControlDistance}>
                                <FloatLabel label={`Average Pace`} value={pace}>
                                  <Search
                                    min={0}
                                    className="search-box"
                                    size="large"
                                    required
                                    style={{ 
                                      width: '90%', 
                                    }}
                                    onChange={e => handleChangePace(e)}
                                  />
                                </FloatLabel>
                              </FormControl>}
                              {isElevationGain === true && <FormControl className={classes.formControlDistance}>
                                <FloatLabel label={`Elevation Gain`} value={elevationGain}>
                                  <Search
                                    min={0}
                                    className="search-box"
                                    size="large"
                                    required
                                    style={{ 
                                      width: '90%', 
                                    }}
                                    onChange={e => handleChangeElevationGain(e)}
                                  />
                                </FloatLabel>
                              </FormControl>}
                              { isTime && <div>
                                <TextField
                                  id="hours"
                                  type="number"
                                  label={`เวลาที่ใช้ (ชั่วโมง)`}
                                  placeholder={`กรอกเวลาที่ใช้ (ชั่วโมง)`}
                                  variant="outlined"
                                  required
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={e=> handleChangeTextFieldHours(e)}
                                  className={classes.TextField}
                                  inputProps={{
                                    min: 0
                                  }}
                                />
                                <TextField
                                  id="minutes"
                                  type="number"
                                  label={`เวลาที่ใช้ (นาที)`}
                                  placeholder={`กรอกเวลาที่ใช้ (นาที)`}
                                  variant="outlined"
                                  required
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={e=> handleChangeTextFieldMinutes(e)}
                                  className={classes.TextField2}
                                  inputProps={{
                                    min: 0
                                  }}
                                />
                                </div>
                              }
                              {distanceError && 
                                <Alert severity="error" className={classes.ticketError}>
                                  กรุณากรอกข้อมูลให้ถูกต้อง (ส่งผลได้ไม่เกินครั้งละ {currentLimit} {selectedRace.distanceUnit})
                                </Alert>
                              }
                              {paceError || elevationGainEror && 
                                <Alert severity="error" className={classes.ticketError}>
                                  กรุณากรอกข้อมูลให้ถูกต้อง
                                </Alert>
                              }
                              { selectedRace.distanceUnit === 'hours' && <div style={{ fontStyle: 'italic', marginTop: '10px', color: 'darkgray' }}> 
                              *** หมายเหตุ เศษนาที ต่ำกว่า 30 นาที ให้ปัดลง เช่น นอน 7:20 ชั่วโมง ให้ปัดเป็นนอน 7 ชั่วโมง 
                              และเศษนาที ตั้งแต่ 30 นาที ให้ปัดขึ้น เช่น นอน 7:37 ชั่วโมง ให้ปัดเป็นนอน 8 ชั่วโมง
                              </div> }
                              { selectedRace.distanceUnit === 'kcal' && <div style={{ fontStyle: 'italic', marginTop: '10px', color: 'darkgray' }}>
                                คำนวณตัวอย่างแคลอรี่ได้จาก <a target="_blank" rel="noopener noreferrer" alt="https://read.thai.run/7405/" href="https://read.thai.run/7405/"> https://read.thai.run/7405/ </a>
                              </div> }
                              { virtualRunMessage !== '' && <div style={{ fontStyle: 'italic', marginTop: '10px', color: 'darkgray' }}>
                                {virtualRunMessage}
                              </div> }
                              <div className={classes.formButtonSubmit} >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  shape="round"
                                  className="btn-cancel"
                                  onClick={() => handleChangeEditBtn()}
                                  style={{ height: '40px', borderRadius: '20px', width: '40%', marginTop: '20px' }}
                                >
                                  <span style={{fontFamily: 'Metropolis'}}>{edit? t('submit.btnBack'): t('submit.btnEditIncorrect')}</span>
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  disabled={distanceError || loading || paceError || elevationGainEror} 
                                  shape="round"
                                  className="btn-affect-cumulative"
                                  style={{ height: '40px', borderRadius: '20px', width: '40%', marginTop: '20px' }}
                                >
                                  {loading? 'loading...' :<span style={{fontFamily: 'Metropolis'}}>{
                                    distance > 0 && isOCREnabled ?
                                      t('submit.btnSubmitCorrect'):
                                      t('submit.btnSubmit')
                                  }</span>}
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        }
                        {!runnerError && ticketItems.length !== 0 && runner.length !== 0 && runnerImage && !distanceError && !distanceErrorhours && !confirmDistanceModal && !paceError &&
                          <div className={classes.formButtonSubmit} >
                            <Button
                              variant="contained"
                              color="primary"
                              shape="round"
                              className="btn-cancel"
                              onClick={() => window.location.href=`/${props.match.params.slug}/submit`}
                              style={{ height: '40px', borderRadius: '20px', width: '40%', marginTop: '20px' }}
                            >
                              <span style={{fontFamily: 'Metropolis'}}>{t('submit.btnBack')}</span>
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={!runner} 
                              shape="round"
                              className="btn-affect-cumulative"
                              style={{ height: '40px', borderRadius: '20px', width: '40%', marginTop: '20px' }}
                            >
                              <span className="btn-font-cumulative" style={{fontFamily: 'Metropolis'}}>{t('submit.btnSubmit')}</span>
                            </Button>
                          </div>
                        }
                      </form>
                      
                    </CardContent>
                    { openModel ?
                      <Modal
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        open
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        className={classes.modal}
                        container={() => rootRef.current}
                      >
                        <div className={classes.paperModel}>
                          <CheckCircleOutlineIcon style={{ fontSize: 100, color: '#05b105' }}/>
                          {submitModalConten}
                          <p style={{ marginTop: '20px' }}>{t('submit.thank')}</p>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonSubmit}
                              onClick={() => window.location.href=`/${props.match.params.slug}/${ticketNumber}`}
                            >
                              <span style={{fontFamily: 'Metropolis'}}>{t('submit.ok')}</span>
                            </Button>
                        </div>
                      </Modal>
                    : '' }
                  </Card>
                }
                { isAfter &&  <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'80vh' }}>
                    <p className="font-close">"หมดเวลาการส่งผล"</p>
                  </div>
                }
                  
              </Grid>
            </Grid>
          
        </div>
        
      </div>
      <Footer />
    </div>
  )
}

export default withTranslation()(Submit)