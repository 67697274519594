import styled from 'styled-components';
import { mediaIpad, mediaIpadmini, mediaMobile  } from './desktop.js';

export const HeaderContainer = styled.div`
  width: 90%;
`
export const BoxContainer = styled.div`
  width: 100%;
  // margin-top: 30px;
  // margin-bottom: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  max-width: 1250px;
  ${ mediaIpadmini.Ipadmini`
    width: 100%;
  ` }
  ${ mediaIpad.Ipad`
    width: 100%;
  ` }
`
export const BoximgContainer = styled.div`
  width: 100%;
  height: 275px;
  display: flex;
  justify-content: center;
  
`
export const Eventday = styled.div`
    // top:-107px;
    // width: 25%;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    padding: 0px 3px;
    text-align: left;
  `
export const Eventtext = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    //padding: 20px 10px;
    // padding-left:10px;
    // padding-right:10px;
    text-align: left;
    ${ mediaMobile.Mobile`
      // max-width: 200px;
    ` }
  `
export const TotalEvent = styled.div`
  width: 100%;
  display: flex;
  // justify-content: flex-end;
  // align-items: flex-end;
  flex-direction: column;
  ${ mediaIpadmini.Ipadmini`
    width: 100%;
  ` }
  ${ mediaIpad.Ipad`
    width: 100%;
  ` }
`
export const EventDonation = styled.div`
    width: 100%;
    padding-top:20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  `
export const Eventamount = styled.div`
    width: 100%;
    height: 70px;
    // margin-top: 30px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${ mediaIpadmini.Ipadmini`
      height: 70px;
    ` }
    ${ mediaMobile.Mobile`
      height: 70px;
    ` }
    // ${ mediaIpad.Ipad`
    //   width: 100%;
    // ` }
  `
export const Eventbutton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 10px;
    align-items: flex-end; 
    ${ mediaIpadmini.Ipadmini`
      justify-content: center;
      padding-right: 0px;
    ` }
    ${ mediaIpad.Ipad`
      justify-content: center;
      padding-right: 0px;
    ` }
  `

export const EventCardWrapper = styled.div`
  cursor: pointer;
  .ant-card {
    border: none;
  }
  .ant-card-body {
    padding: 8px 0;
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    margin: unset;
  }
  p {
    margin: 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
  .faIcon {
    width: 30px;
    display: inline-block;
    text-align: center;
    transform: translate(-6px, 0);
  }
  ${mediaMobile.Mobile`
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  `}
  ${mediaIpadmini.Ipadmini`
    
  `}
  ${mediaIpad.Ipad`
    
  `}
`