import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'

const resources = {
	en: {
		translation: {
			header: {
				"context": "Contact Us",
				"search": "Search"
			},
			finisherwall: {
				"register": "Register",
				"resultSubmit": "Submit Result",
				"resultSearch": "Search my result",
				"submit": "Submission during",
				"allResult": "All Results",
				"weekResult": "weekly",
				"runner": "Runners",
				"teams": "Teams",
				"day": "Days",
				"bibSearch": "Search by BIB number",
				"searchHolder": "name / BIB",
				"searchBibHolder": "BIB",
				"gender": "gender",
				"all": "all",
				"male": "male",
				"female": "female",
				"LGBTQ+" : "LGBTQ+",
				"resultall": "Total",
				"lastresult": "Last Submission",
				"detail": " ",
				"distance": "distance",
				"ofall": "of",
				"ecer": "eCer Download",
				"submmitCount": " ",
				"submmitTime": " ",
				"totaldistance": " ",
				"submitScore": " "
			},
			submit: {
				"search": "BIB number",
				"submit": "Send Results",
				"btnSearch": "Search",
				"name": "name",
				"upload": "upload",
				"distance": "Result",
				"btnBack": 'back',
				"btnSubmit": 'submit',
				"btnSubmitCorrect": 'Correct',
				"register": "have not registered?",
				"registerClick": "Click here",
				"race": "RACE",
				"accumulated": "Accumulated",
				"congrat": "Congratulations!!",
				"complete": "you completed the race",
				"ecer": "e-cer",
				"ecerload": "e-cerdowlaodClick!",
				"thank": "Thank you for joining.",
				"ok": "OK",
				"resive": "We received your result.",
				"nowaccumulated": "accumulated.",
				"more": "more",
				"tocomplete": "left to complete.",
				"btnFinisherwall": "finisher wall",
				"btnEdit": "Edit",
				"btnEditIncorrect": "Incorrect",
				"loadmore" : "load more",
				"btnRemoveResult" : "Remove Result"
			},
			menu: {
				"Home" : "Home",
				"Events" : "Events",
				"PastEvents" : "Past Events",
			}
			
		}
	},
	th: {
		translation: {
			header: {
				"context": "ติดต่อเรา",
				"search": "ค้นหางาน..."
			},
			finisherwall: {
				"register": "สมัคร",
				"resultSubmit": "ส่งผลเก็บระยะ",
				"resultSearch": "ค้นหาผลที่ส่งแล้ว",
				"submit": "ส่งผล",
				"allResult": "ดูผลรวม",
				"weekResult": "ดูผลรายสัปดาห์",
				"runner": "คน",
				"teams": "ทีม",
				"day": "วัน",
				"bibSearch": "ค้นหาผลที่ส่งแล้วด้วยบิบ...",
				"searchHolder": "ค้นหาชื่อ-นามสกุล / เลขบิบ",
				"searchBibHolder": "ค้นหาเลขบิบ",
				"all": "ทั้งหมด",
				"gender": "เพศ",
				"male": "ชาย",
				"female": "หญิง",
				"LGBTQ+":"LGBTQ+",
				"resultall": "ส่งผลทั้งหมดรวม",
				"lastresult": "ส่งผลล่าสุด",
				"detail": "รายละเอียด",
				"distance": "ระยะทาง",
				"ofall": "จากเป้าหมายทั้งหมด",
				"ecer": "ดาวน์โหลด eCer",
				"submmitCount": "จำนวนผู้ส่งผล",
				"submmitTime": "ระยะเวลา",
				"totaldistance": "ระยะทางรวม",
				"submitScore": "คะแนน"
			},
			submit: {
				"search": "เลขบิบ หรือเบอร์โทรศัพ หรืออีเมล (อย่างใดอย่างหนึ่ง)",
				"submit": "ส่งผลเก็บระยะ",
				"btnSearch": "ค้นหา",
				"name": "ชื่อ - นามสกุล",
				"upload": "อัพโหลดไฟล์",
				"distance": "ผลที่ต้องการสะสมในครั้งนี้",
				"btnBack": 'กลับ',
				"btnSubmit": 'ส่งผล',
				"btnSubmitCorrect": 'ถูกต้อง',
				"register": "ยังไม่ได้สมัครงานนี้?",
				"registerClick": "สมัคร",
				"race": "ประเภทกิจกรรม",
				"accumulated": "สะสมไปแล้ว",
				"congrat": "ยินดีด้วยคุณได้ส่งผลครบตามสัญญาแล้ว",
				"complete": "คุณจะได้รับ e-mail เพื่อยืนยันการส่งผล",
				"ecer": "e-cer",
				"ecerload": "ดาวน์โหลด e-cerคลิก !!",
				"thank": "ขอบคุณที่ร่วมกิจกรรม",
				"ok": "กลับสู่หน้าเก็บผลสะสม",
				"resive": "ได้รับผลเรียบร้อยแล้ว",
				"nowaccumulated": "คุณสะสมได้",
				"more": "อีก",
				"tocomplete": "จะถึงเป้าหมายของคุณ",
				"btnFinisherwall": "finisher wall",
				"btnEdit": "แก้ไข",
				"btnEditIncorrect": "ไม่ถูกต้อง",
				"loadmore" : "เพิ่มเติม...",
				"btnRemoveResult" : "ลบผลวิ่ง"

			},
			menu: {
				"Home" : "หน้าหลัก",
				"Events" : "งานวิ่ง",
				"PastEvents" : "งานวิ่งที่ผ่านมา",
			}
			
		}
	}
}


i18n
  .use(initReactI18next)
	.use(LanguageDetector)
  .init({
		resources,
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;