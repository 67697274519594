import React, { useState, useEffect } from 'react'
import _get from 'lodash/get'

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function canvasToBase64(data,rankMode) {
  if (!data) return
  const index1st = (data.length === 5 || data.length === 4) ? 2 : (data.length === 3) ? 1 :  data.length - 1
  const WIDTH = data.length > 3 ? ((data.length * 70) + ((data.length + 1) * 100 )) : 610 
  const HEIGHT = 450
  const mainDistance = rankMode === 'utpscore' ? _get(data, `${index1st}.totalRunningScore`, 0) : _get(data, `${index1st}.value`, 0)
  const mainHeight = 250

  const graphWidth = 70
  const graphSpace = 100
  let graphX = graphSpace
  const color = ["#F2726F", "#FFC532", "#67CDF2", "#814281", "#00B347"]
  let textOrder = []
  if(data.length < 4){
    textOrder = ["2nd", "1st", "3rd"]
  } else {
    textOrder = ["4th", "2nd", "1st", "3rd", "5th"]
  }
  
  const canv = document.createElement('canvas')
  canv.width = WIDTH
  canv.height = HEIGHT
  var ctx = canv.getContext("2d");
  if (data.length === 1 ) {
      const valueDistance = rankMode === 'utpscore' ? _get(data, '0.totalRunningScore', 0).toFixed(2) : _get(data, '0.value', 0).toFixed(2)
      const distanceUnit = _get(data, '0.distanceUnit')
      const label = _get(data, '0.label', '')
      const graphHeight = valueDistance * mainHeight / mainDistance
      const graphY = HEIGHT - graphHeight - 40
      const orderY = graphY + graphHeight + 30

      ctx.beginPath();
      ctx.rect(graphX, graphY, graphWidth, graphHeight);
      ctx.fillStyle = color[1]
      ctx.fillRect(270, graphY, graphWidth, graphHeight);
  
      ctx.fillStyle = "#000000"
      ctx.font = "24px Arial"
      ctx.textAlign = "center"
      ctx.fillText(label, 270+35, graphY - 40)
  
      ctx.font = "16px Arial"
      ctx.textAlign = "center"
      ctx.fillText(`${numberWithCommas(valueDistance)} ${distanceUnit}`, 270+35, graphY - 10)
  
      ctx.font = "24px Arial"
      ctx.textAlign = "center"
      ctx.fillText('1st', 270+35, orderY)
  } else {
    data.forEach((item, index) => {
      
      const valueDistance = rankMode === 'utpscore' ? _get(item, 'totalRunningScore', 0).toFixed(2) : _get(item, 'value', 0).toFixed(2)
      const distanceUnit = _get(item, 'distanceUnit')
      const label = _get(item, 'label', '')
      const labelLength = _get(label, 'length') | 0
      let labelFont = 35
      if (labelLength > 30) {
        labelFont = 15
      } else if (labelLength > 24) {
        labelFont = 20
      } else if (labelLength > 20) {
        labelFont = 24
      } else if (labelLength > 10) {
        labelFont = 30
      }
      
      const graphHeight = valueDistance * mainHeight / mainDistance
      const graphY = HEIGHT - graphHeight - 40
      const orderY = graphY + graphHeight + 30
  
      ctx.beginPath();
      ctx.rect(graphX, graphY, graphWidth, graphHeight);
      ctx.fillStyle = color[index]
      ctx.fillRect(graphX, graphY, graphWidth, graphHeight);
  
      ctx.fillStyle = "#000000"
      ctx.font = `${labelFont}px Arial`
      ctx.textAlign = "center"
      ctx.fillText(label, graphX+35, graphY - 40)
  
      ctx.font = "16px Arial"
      ctx.textAlign = "center"
      ctx.fillText(`${numberWithCommas(valueDistance)} ${distanceUnit}`, graphX+35, graphY - 10)
  
      ctx.font = "24px Arial"
      ctx.textAlign = "center"
      ctx.fillText(textOrder[index], graphX+35, orderY)
  
      graphX = graphWidth + graphSpace + graphX
    })
  }
  
  
  const base64 = canv.toDataURL()

  return base64
}

function RenderChart({ dataSource , rankMode }) {
  const [base64, setBase64] = useState()
  
  useEffect(() => {
    const base64 = canvasToBase64(dataSource, rankMode)

    setBase64(base64)
  }, [dataSource])

  if(!dataSource) {
    return <div>loading data..</div>
  }
  return <div style={{ maxWidth: 800, margin: 'auto'}}>
    {base64 ? <img src={base64} width="100%" alt="preview-chart"/> : 'loading'}
  </div>
}

export default RenderChart