import styled from 'styled-components';
import { mediaIpad, mediaIpadmini, mediaMobile,mediaMobileFormAnt  } from './desktop.js';

export const PopupContainer = styled.div`
  width: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding-bottom: 20px;
  ${ mediaMobile.Mobile`
    width: 95%;
  ` }
  ${ mediaIpadmini.Ipadmini`
    width: 95%;
  ` }
  ${ mediaIpad.Ipad`
    width: 95%;
  ` }
`
export const HeaderContainer = styled.div`
  width: 90%;
`
export const Text = styled.div`
width: 10%;
padding-right: 2%;
`
export const Textlong = styled.div`
  width:80%;
`
export const CheckBib = styled.div`
width: 80%;
${ mediaMobile.Mobile`
    // flex-direction: column;
  ` }
  ${ mediaIpadmini.Ipadmini`
    // flex-direction: column;
  ` }
  ${ mediaIpad.Ipad`
    width: 80%;
  ` }
`
export const CheckBib2 = styled.div`
width: 57%;
  ${ mediaIpadmini.Ipadmini`
    // flex-direction: column;
  ` }
  ${ mediaIpad.Ipad`
    width: 57%;
  ` } 
  ${mediaMobileFormAnt.Mobile2`
  width: 80%;
  ` }
  ${ mediaMobile.Mobile`
    width: 80%;
  ` }
`
export const Channel = styled.div`
width:80%;
`
export const Text1 = styled.div`
width: 25%;
padding-right: 2%;
`
export const Channel1 = styled.div`
width: 80%;
${ mediaIpad.Ipad`
    width: 80%;
  ` }
  ${mediaMobileFormAnt.Mobile2`
  width: 70%;
  ` }
  ${ mediaMobile.Mobile`
    width: 70%;
  ` }
`
export const Km = styled.div`
width: 40px;
padding-left: 5px;
`
export const Footer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.800);
  color:#FFF;
  bottom: 0px;
  margin:0px auto;
  position:fixed;
  ${ mediaMobile.Mobile`
    width: 100%;
    display: none;
  ` }
  ${ mediaIpadmini.Ipadmini`
    width: 100%;
  ` }
  ${ mediaIpad.Ipad`
    width: 100%;
  ` }
`