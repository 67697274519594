import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import _get from "lodash/get";
import queryString from "query-string";
import { graphql, compose, withApollo } from "react-apollo";
import request from "request";
import zeroFill from "zero-fill";
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  InputNumber,
  message,
  Spin,
  Modal,
  Menu,
  Icon,
  Dropdown,
  Select,
} from "antd";
import {
  PopupContainer,
  Channel,
  Channel1,
  Km,
  CheckBib,
  HeaderContainer,
  CheckBib2,
} from "../../styled-components/popup.js";
import loadImage from "blueimp-load-image";
import logo from "../../img/logo.png";
import "./css/popup.css";
import raceClient from "~/apollo-clients/race";
import eventTicketItemSearchQuery from "../../../graphql/queries/eventTicketItemSearch.gql";
import eventTicketltemQuery from "../../../graphql/queries/eventTicketItem.gql";
import raceBySlugQuery from "~/graphql/queries/raceBySlug.gql";
import submitraceMutation from "~/graphql/mutations/submitrace.gql";
import ImageUploader from "../../../components/ImageUploader.js";
import withTracker from "../../../hocs/withTracker.js";

const FormItem = Form.Item;
const MAX_DIMENSION = 1800;

function qs(number, name, event) {
  const qs = {
    Event: `${event}`,
    Name: `${name}`,
    Bib: zeroFill(4, `${number}`),
    gunTime: "1",
    CheckPointName: "Finish",
    Race: "normal",
  };

  request.get({ url: "https://results.thai.run/hook", qs: qs }, (e, r, res) => {
    console.log(res);
  });
}

const Modalcontent = ({ slug, number, name, event }) => {
  return (
    <div style={{ textAlign: "center" }}>
      ต้องการ e-Cer หรือไม่!{" "}
      <a
        onClick={() => qs(number, name, event)}
        href={`https://ecer.thai.run/${slug}`}
      >
        ดาวน์โหลด e-Cer คลิก!!!
      </a>
    </div>
  );
};

class Popup extends Component {
  state = {
    isOpen: [],
    runner: null,
    runnerAllEvent: null,
    bibSearch: "",
    loading: false,
    TicketItem: null,
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      const { timeStart, timeEnd } = this.state;
      const openSubmission = moment(timeStart, "YYYY-MM-DD HH:mm"); // set time to open
      const closeSubmission = moment(timeEnd, "YYYY-MM-DD HH:mm"); // set time to close
      const isOpen = moment().isBetween(openSubmission, closeSubmission);
      this.setState({
        isOpen,
      });
    }, 1000);
  }
  componentWillReceiveProps(nextProps) {
    const { raceBySlug } = nextProps.data;
    const timeStart = moment(
      raceBySlug.settings.virtualRun.period.start
    ).format("YYYY-MM-DD HH:mm");
    const timeEnd = moment(raceBySlug.settings.virtualRun.period.end).format(
      "YYYY-MM-DD HH:mm"
    );
    this.setState({
      timeStart,
      timeEnd,
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  distanceValidator = (rule, value, callback) => {
    if (value < 1) {
      callback("Please enter a distance");
    }
    callback();
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.form.validateFields((err, values) => {
      if (err) {
        this.setState({ loading: false });
        console.error(err);
        return;
      }

      const { _id, participant, number } = this.state.runner;
      const participantId = participant._id;
      const ticketItemId = _id;
      const raceId = this.props.data.raceBySlug._id;
      const eventId = this.props.data.raceBySlug.event._id;
      const { distance, runnerImageFile, activityImageFile } = values;

      const record = {
        number,
        distance,
        eventId,
        participantId,
        raceId,
        ticketItemId,
      };
      this.props
        .submitRun({
          variables: {
            record,
            activityImageFile,
            runnerImageFile,
          },
        })
        .then(() => {
          const slug = this.props.data.raceBySlug.slug;
          const event = _get(this.props, "data.raceBySlug.name.en");
          const name = this.state.runner.participant.profile.firstName;
          const number = this.state.runner.number;
          Modal.success({
            title: "ผลการวิ่งได้รับแล้ว ขอบคุณที่ร่วมวิ่งกับ Thairun",
            content: (
              <Modalcontent
                slug={slug}
                name={name}
                number={number}
                event={event}
              />
            ),
            onOk: () => (window.location.href = `/~${slug}`),
          });
        })
        .catch((err) => {
          message.error(err);
        });
    });
  };
  beforeUpload = (file, files) => {
    loadImage(
      file,
      (canvas) => {
        const imgUrl = canvas.toDataURL("image/jpeg", 0.95);
        file.thumbUrl = imgUrl;
        this.props.form.setFieldsValue({
          file,
        });
      },
      {
        maxWidth: MAX_DIMENSION,
        maxHeight: MAX_DIMENSION,
        contain: true,
        orientation: true,
        canvas: true,
      }
    );
    return false;
  };

  checkBib = (searchBib) => {
    const { pathname, search } = this.props.location;
    const searchQuery = queryString.parse(search);
    searchQuery.s = searchBib;
    searchQuery.p = undefined;
    const searchString = queryString.stringify(searchQuery);
    this.props.history.push({ pathname, search: searchString });

    const bib = this.props.form.getFieldValue("bib");
    if (bib === undefined) {
      return message.error("Not found data");
    }

    raceClient
      .query({
        query: eventTicketItemSearchQuery,
        variables: {
          search: bib,
          eventId: this.props.data.raceBySlug.event._id,
        },
      })
      .then(({ data }) => {
        if (
          !data.eventTicketItemSearch ||
          data.eventTicketItemSearch.length === 0
        ) {
          return message.error("Not found");
        }
        this.setState({
          TicketItem: data.eventTicketItemSearch,
        });
      });
  };

  handleChange = (value) => {
    raceClient
      .query({
        query: eventTicketltemQuery,
        variables: {
          number: parseInt(value, 10),
          eventId: this.props.data.raceBySlug.event._id,
        },
      })
      .then(({ data }) => {
        if (!data.eventTicketItem) {
          return message.error("BIB Not found");
        }
        this.setState({
          runner: data.eventTicketItem,
        });
      });
  };

  render() {
    if (!this.props.data.raceBySlug) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin tip="Loading...raceBySlug" />
        </div>
      );
    }

    const raceBySlug = this.props.data.raceBySlug;
    const name = _get(raceBySlug, "name.en");
    const { getFieldDecorator } = this.props.form;
    const { runner, TicketItem } = this.state;
    const bibDecorator = getFieldDecorator("bib", {
      rules: [
        {
          required: true,
          message: "Please enter a message.",
        },
      ],
    });

    const distanceDecorator = getFieldDecorator("distance", {
      rules: [
        {
          required: true,
          message: "Please enter a distance ",
        },
        {
          validator: this.distanceValidator,
        },
      ],
    });

    const fileDecorator = getFieldDecorator("activityImageFile", {
      rules: [{ required: true }],
    })(<ImageUploader />);

    const fileactivityImageFile = getFieldDecorator(
      "runnerImageFile",
      {}
    )(<ImageUploader />);

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const menu = (
      <Menu
        mode="horizontal"
        theme="dark"
        defaultSelectedKeys={["1"]}
        style={{
          background: "rgba(0, 0, 0, 0.60)",
          marginTop: "0px",
          borderRadius: "0px 0px 5px 5px",
          fontFamily: "Kanit",
        }}
      >
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href="/">
            หน้าหลัก
          </a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col style={{ marginTop: "100px" }} />
          <Col className="header">
            <HeaderContainer>
              <div className="logo">
                <a href="/">
                  <img className="logo-top" src={logo} alt="logo" />
                </a>
                <Menu
                  className="nav-test"
                  mode="horizontal"
                  theme="light"
                  style={{
                    lineHeight: "64px",
                    background: "transparent",
                    color: "rgb(200, 200, 200)",
                    marginTop: "15px",
                  }}
                >
                  <Menu.Item key="1" className="button-nav">
                    <a className="main" href="/">
                      หน้าหลัก
                    </a>
                  </Menu.Item>
                </Menu>
              </div>
            </HeaderContainer>
            <div className="nav-test1">
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={["click"]}
              >
                <Icon type="bars" style={{ fontSize: 30, color: "#fff" }} />
              </Dropdown>
            </div>
          </Col>
        </Row>
        {this.state.isOpen && this.state.isOpen ? (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PopupContainer>
                <div className="crop-name-event">
                  <div className="show-name-event">
                    <p className="font-bib">{name}</p>
                  </div>
                </div>
                <div className="Control-Content">
                  <CheckBib>
                    <FormItem
                      hasFeedback
                      {...formItemLayout}
                      label="ส่งผลการวิ่ง"
                    >
                      {bibDecorator(
                        <Input.Search
                          placeholder="เลขบิบ เบอร์โทรศัพท์ E-mail"
                          disabled={!!runner}
                          onSearch={this.checkBib}
                          enterButton={!runner && "ค้นหา"}
                        />
                      )}
                    </FormItem>
                  </CheckBib>
                </div>

                {TicketItem && (
                  <div className="Content1">
                    <CheckBib2>
                      <Select
                        placeholder="Select Name & Race"
                        onChange={this.handleChange}
                      >
                        {TicketItem.map((ticket) => (
                          <Select.Option key={ticket._id} value={ticket.number}>
                            {ticket.participant.profile.firstName}{" "}
                            {ticket.ticketType.race.name.th}
                          </Select.Option>
                        ))}
                      </Select>
                    </CheckBib2>
                  </div>
                )}
                {runner && runner.ticketType.race.distance > 0 && (
                  <div style={{ marginTop: "5px" }}>
                    <div className="Control-Content">
                      <Channel>
                        <FormItem
                          hasFeedback
                          {...formItemLayout}
                          label="ภาพผลการวิ่ง"
                        >
                          {fileDecorator}
                        </FormItem>
                      </Channel>
                    </div>
                    <div className="Control-Content">
                      <Channel>
                        <FormItem
                          hasFeedback
                          {...formItemLayout}
                          label="ภาพนักวิ่ง"
                        >
                          {fileactivityImageFile}
                        </FormItem>
                      </Channel>
                    </div>
                    <div className="Control-Content">
                      <Channel1>
                        <FormItem
                          hasFeedback
                          {...formItemLayout}
                          label="ระยะวิ่งครั้งนี้"
                        >
                          {distanceDecorator(
                            <InputNumber
                              placeholder="กรอกระยะทางวิ่ง"
                              style={{ width: "100%" }}
                            />
                          )}
                        </FormItem>
                      </Channel1>
                      <Km>
                        <p className="fontkm">km</p>
                      </Km>
                    </div>
                    <div className="button-Cancel">
                      <FormItem>
                        <Button
                          className="btn-Cancel1"
                          htmlType="submit"
                          onClick={(e) => this.handleSubmit(e)}
                          disabled={!runner}
                          loading={this.state.loading}
                        >
                          ส่งผลการวิ่ง
                        </Button>
                      </FormItem>
                    </div>
                  </div>
                )}
              </PopupContainer>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <HeaderContainer>
              <p className="font-close">"หมดเวลาการส่งผล"</p>
            </HeaderContainer>
          </div>
        )}
      </Form>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  Form.create(),
  graphql(raceBySlugQuery, {
    options: (props) => ({
      variables: { slug: props.match.params.slug },
      client: raceClient,
    }),
  }),
  graphql(submitraceMutation, {
    name: "submitRun",
    options: {
      client: raceClient,
    },
  }),
  withTracker
)(Popup);
