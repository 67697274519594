import React, { useState, useMemo } from "react";
import { Spin, Modal, Progress, Button, Divider } from "antd";
import queryString from "query-string";
import { useQuery } from "@apollo/react-hooks";
import { compose } from "react-apollo";
import _get from "lodash/get";
import _sumBy from "lodash/sumBy";
import "./css/style.css";
import FacebookBtn from "~/V3/components/FacebookBtn";
import { EcerImgContainer } from "../../styled-components/event.js";
import { Typography } from "@material-ui/core";
import { Card, List } from "antd";
import Header from "../../../V3/components/header";
import Footer from "../../../V3/components/footer";
import raceClient from "../../../apollo-clients/race";
import eventRunFromSlugQuery from "~/graphql/queries/eventRunFromSlug.gql";
import eventRunsQuery from "~/graphql/queries/eventRuns.gql";
import ticketItemOneQuery from "~/graphql/queries/ticketItemOne.gql";
import withTracker from "../../../hocs/withTracker";
import { withTranslation } from "react-i18next";

const googleStorageBucket = process.env.REACT_APP_GOOGLE_STORAGE_BUCKET;
const { Meta } = Card;

const RaceProgressBars = ({
  noEcer,
  bibNumber,
  runItems,
  distanceMode,
  t,
  races,
  locale,
  number,
  isAutoApproved,
  race,
  ecerHtml,
  rankMode
}) => {
  const tempRunItems = runItems;
  const completedRaces = races.filter(({ distance, _id }) => {
    const runItems = tempRunItems.filter(
      (item) => `${item.raceId}` === `${_id}`
    );
    let sumDistance = 0;
    sumDistance = _sumBy(runItems, "distance", 0) || 0;
    const calculate = (sumDistance * 100) / distance;
    return calculate >= 100;
  });
  const RunningScoreCalculator = (distance, pace, elevationGain) => {
    let runningValue = 0
    if(pace >= 10) {
      runningValue = 1
    } else if(pace >= 8){
      runningValue = 1.5
    } else if(pace >= 6){
      runningValue = 1.75
    } else {
      runningValue = 2
    }
    const result = (distance * runningValue) + (elevationGain * 0.02)
    return result
  }
  return (
    <div style={{ padding: "0 10px" }}>
      {_get(completedRaces, "length") === _get(races, "length") && (
        <div>
          <h4 className="success">
            {_get(race, ["name", locale], "").toUpperCase()} {t("submit.race")}{" "}
            CONGRATULATION!
          </h4>
          {!noEcer && (
            <Button
              type="primary"
              shape="round"
              size="large"
              className="btn-affect-sub btn-mobile-right"
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
              href={`https://ecer.thai.run/${race.slug}/${number}`}
            >
              <span style={{ fontSize: "14px" }}>{t("finisherwall.ecer")}</span>
            </Button>
          )}
          {!noEcer ? ecerHtml({ number, race }) : ""}
          <React.Fragment>
            <div style={{ marginTop: "35px" }}>
              <Typography variant="h5" style={{ display: "inline-block" }}>
                {
                  { en: "COMPLETE ALL RACES", th: "กิจกรรมทั้งหมดสําเร็จแล้ว" }[
                    locale
                  ]
                }
              </Typography>
              <div style={{ maxWidth: "100%" }}>
                <Progress
                  percent={100}
                  strokeColor={"#4BB543"}
                  style={{ color: "#4BB543" }}
                />
              </div>
            </div>
          </React.Fragment>
          <Divider />
        </div>
      )}
      {races.map((race$) => {
        const { name, distanceUnit, distance, slug, _id } = race$;
        let sumDistance = 0;
        let sumRunningScore = 0
        const runItems = tempRunItems.filter(
          (item) => `${item.raceId}` === `${_id}`
        );
        if (runItems && distanceMode === "cumulative") {
          if (isAutoApproved && slug !== 'vr1000kay6') {
            sumDistance = _sumBy(runItems, "distance", 0) || 0;
            sumRunningScore = _sumBy(runItems, function(r) { return RunningScoreCalculator(r.distance, r.pace, r.elevationGain) }, 0) || 0
          } else {
          }
        }
        if (distanceMode === "recent") {
          sumDistance = _get(runItems, [0, "distance"], 0) || 0;
        }
        const calculate = (sumDistance * 100) / distance;
        if (calculate >= 100) {
          return (
            <React.Fragment>
              <h4 className="success">
                {name[locale].toUpperCase()} {t("submit.race")} CONGRATULATION!
              </h4>
              {calculate >= 100 && !noEcer && (
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="btn-affect-sub btn-inside-finisherwall btn-mobile-right"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  href={`https://ecer.thai.run/${slug}/${number}`}
                >
                  <span style={{ fontSize: "14px" }}>
                    {t("finisherwall.ecer")}
                  </span>
                </Button>
              )}
              {!noEcer && sumDistance >= distance
                ? ecerHtml({ number, race: race$ })
                : ""}
              {distanceMode === "cumulative" || distanceMode === "recent" ? (
                <React.Fragment>
                  <div style={{ marginTop: "35px" }}>
                    <Typography
                      variant="h4"
                      style={{ display: "inline-block" }}
                    >
                      {sumDistance.toFixed(2)} {distanceUnit}
                    </Typography>
                    <Typography
                      style={{ display: "inline-block", paddingLeft: "5px" }}
                    >{` ${t(
                      "finisherwall.ofall"
                    )} ${distance} ${distanceUnit}`}</Typography>
                    <div style={{ maxWidth: "100%" }}>
                      <Progress
                        percent={calculate.toFixed(2)}
                        strokeColor={"#4BB543"}
                        style={{ color: "#4BB543" }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <Typography>
                  {sumDistance.toFixed(2)} {distanceUnit}
                </Typography>
              )}
              <Divider />
            </React.Fragment>
          );
        }
        return (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h4 className="progress">
              {name[locale].toUpperCase()} {t("submit.race")} PROGRESS
            </h4>
            {distanceMode === "cumulative" || distanceMode === "recent" ? (
              <React.Fragment>
                <div>
                  <Typography variant="h4" style={{ display: "inline-block" }}>
                    {sumDistance.toFixed(2)} {distanceUnit}
                  </Typography>
                  <Typography
                    style={{ display: "inline-block", paddingLeft: "5px" }}
                  >{` ${t(
                    "finisherwall.ofall"
                  )} ${distance} ${distanceUnit}`}</Typography>
                </div>
                <div style={{ maxWidth: "100%" }}>
                  <Progress
                    percent={calculate.toFixed(2)}
                    strokeColor={"#f9a01b"}
                    style={{ color: "#f9a01b" }}
                  />
                </div>
                {rankMode === 'utpscore' && <React.Fragment>
                  <Typography
                    variant="h6"
                    style={{ display: "inline-block" }}
                  >
                    accumulate Mileage: {sumDistance.toFixed(2)} {distanceUnit}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ display: "inline-block" }}
                  >
                    accumulate Point: {sumRunningScore} Points
                  </Typography>
                </React.Fragment>
                }
              </React.Fragment>
            ) : (
              <Typography>
                {sumDistance.toFixed(2)} {distanceUnit}
              </Typography>
            )}
            <Divider />
          </div>
        );
      })}
    </div>
  );
};
const finisherwallUser = (props) => {
  const [raceRuns, setRaceRuns] = useState({});
  const [loadingRun, setLoadingRun] = useState(false);

  const { search } = props.location;
  const { p: page } = queryString.parse(search);
  const { t } = props;

  const eventRunFromSlug = useQuery(eventRunFromSlugQuery, {
    variables: { slug: props.match.params.slug },
    client: raceClient,
  });

  const eventRuns = useQuery(eventRunsQuery, {
    client: raceClient,
    skip: true,
  });
  const ticketItem = useQuery(ticketItemOneQuery, {
    client: raceClient,
    variables: {
      slug: props.match.params.slug,
      number: parseInt(_get(props, "match.params.bibNumber"), 10),
    },
  });
  const eventId = _get(eventRunFromSlug, "data.eventRunFromSlug._id");
  const isAutoApproved = _get(
    eventRunFromSlug,
    "data.eventRunFromSlug.settings.virtualRun.isAutoApproved"
  );
  useMemo(() => {
    if (eventId) {
      setLoadingRun(true);
      eventRuns
        .refetch({
          eventId: eventId,
          number: parseInt(props.match.params.bibNumber, 10),
          perPage: 500,
          page,
        })
        .then((result) => {
          setRaceRuns(result.data.raceRuns);
          setLoadingRun(false);
        });
    }
  }, [eventId]);

  const handleRunClick = ({ runnerImage, activityImage }) => (e) => {
    Modal.warning({
      width: "50vw",
      maskClosable: true,
      bodyStile: { padding: "6px" },
      content: (
        <React.Fragment>
          <div style={{ marginLeft: "-38px" }}>
            {runnerImage && (
              <img
                alt="runner"
                src={_get(runnerImage, "original.url")}
                style={{ maxWidth: "100%" }}
              />
            )}
            {activityImage && (
              <img
                alt="activity"
                src={_get(activityImage, "original.url")}
                style={{ maxWidth: "100%" }}
              />
            )}
          </div>
        </React.Fragment>
      ),
    });
  };

  if (eventRunFromSlug.loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  if (!eventRunFromSlug && !eventRunFromSlug.data.eventRunFromSlug) {
    return <div>eventRunFromSlugQuery not found</div>;
  }
  const event = eventRunFromSlug.data.eventRunFromSlug;
  const { distanceMode } = event.settings.virtualRun;
  const noEcer = event.settings.virtualRun.noEcer;
  const ecerV2 = event.settings.virtualRun.ecerV2;
  const rankMode = event.settings.virtualRun.rankMode;

  if (loadingRun) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  const RunningScoreCalculator = (distance, pace, elevationGain) => {
    let runningValue = 0
    if(pace >= 10) {
      runningValue = 1
    } else if(pace >= 8){
      runningValue = 1.5
    } else if(pace >= 6){
      runningValue = 1.75
    } else {
      runningValue = 2
    }
    const result = (distance * runningValue) + (elevationGain * 0.02)
    return result
  }

  const runItemsNotUndefined = _get(raceRuns, "items", []);
  const runItemNotDeleted = runItemsNotUndefined.filter((run) => {
    return run.deleted !== true;
  });
  const runItems = runItemNotDeleted.filter((run) => {
    return run.bibNumber !== "undefined";
  });
  const runItemsApproved = runItems.filter((run) => {
    return run.status === "approved" && run.checked !== null;
  });
  const runItemsWaitting = runItems.filter((run) => {
    return run.status === "approved" && run.checked === null;
  });
  const runItemsReject = runItems.filter((run) => {
    return run.status === "rejected" && run.checked !== null;
  });
  let sumDistance = 0;
  let runItemsFilter = [];
  let sumRunningScore = 0
  if (runItems && distanceMode === "cumulative") {
    if (isAutoApproved && eventId !== '648b161f65dc5b3148389b26') {
      sumDistance = _sumBy(runItems, "distance", 0) || 0;
      runItemsFilter = runItems;
      sumRunningScore = _sumBy(runItems, function(r) { return RunningScoreCalculator(r.distance, r.pace, r.elevationGain) }, 0) || 0
    } else {
      sumDistance = _sumBy(runItemsApproved, "distance", 0) || 0;
      runItemsFilter = runItemsWaitting
        .concat(runItemsReject)
        .concat(runItemsApproved);
      sumRunningScore = _sumBy(runItemsApproved, function(r) { return RunningScoreCalculator(r.distance, r.pace, r.elevationGain) }, 0) || 0
    }
  }
  if (distanceMode === "recent") {
    sumDistance = _get(runItems, [0, "distance"], 0) || 0;
    runItemsFilter = runItems;
  }
  if (distanceMode === "once") {
    if (isAutoApproved && eventId !== '648b161f65dc5b3148389b26') {
      sumDistance = _sumBy(runItems, "distance", 0) || 0;
      runItemsFilter = runItems;
    } else {
      sumDistance = _sumBy(runItemsApproved, "distance", 0) || 0;
      runItemsFilter = runItemsWaitting
        .concat(runItemsReject)
        .concat(runItemsApproved);
    }
  }
  const locale = props.i18n.language.search("en") > -1 ? "en" : "th";
  const ticketItemOne = _get(ticketItem, ["data", "ticketItemOne"]);
  const dupDistance = _get(ticketItemOne, "ticketType.race.distance");
  const number = _get(ticketItemOne, "number");
  const firstName = ticketItemOne
    ? _get(ticketItemOne, "participant.profile.firstName")
    : "not";
  const lastName = ticketItemOne
    ? _get(ticketItemOne, "participant.profile.lastName")
    : "found";
  const distanceUnit = _get(ticketItemOne, "ticketType.race.distanceUnit");
  const races = _get(ticketItemOne, "ticketType.races");
  const race = _get(ticketItemOne, "ticketType.race");
  const ticketItemId = _get(ticketItemOne, "_id");
  const raceId = _get(ticketItemOne, "ticketType.race._id");
  const registrationId = _get(ticketItemOne,'registrationId')
  const bibNumber = props.match.params.bibNumber;
  const slug = props.match.params.slug;
  const calculate = (sumDistance * 100) / dupDistance;
  const ecerHtml = ({ number, race }) => {
    if (ecerV2) {
      const imgPath =
        `events/${race.eventId}/media/ecer/race/${race._id}/${number}.jpg` +
        "?t=" +
        Math.floor(Math.random() * 1000);
      const src = `${googleStorageBucket}/${imgPath}`;
      return (
        <div>
          <EcerImgContainer>
            <a
              target="_blank"
              alt={`${bibNumber}`}
              href={src}
              className="boxEcer"
            >
              <img
                style={{ maxWidth: "500px", width: "100%", height: "100%" }}
                src={src}
                alt="ecer"
              />
            </a>
          </EcerImgContainer>
          <div style={{ textAlign: "center" }}>
            <FacebookBtn link={src} />
          </div>
        </div>
      );
    }
    return (
      <EcerImgContainer>
        <a
          target="_blank"
          alt={`${bibNumber}`}
          href={`https://ecer.thai.run/${slug}/${number}`}
          className="boxEcer"
        >
          <img
            style={{ maxWidth: "500px", width: "100%", height: "100%" }}
            src={`https://storage.googleapis.com/s.cert.thai.run/${slug}/${number}.jpg`}
            alt="ecer"
          />
        </a>
      </EcerImgContainer>
    );
  };
  const ecerLinkHtml = ({ number, race, slug }) => {
    if (ecerV2) {
      const imgPath =
        `events/${race.eventId}/media/ecer/race/${race._id}/${number}.jpg` +
        "?t=" +
        Math.floor(Math.random() * 1000);
      const src = `${googleStorageBucket}/${imgPath}`;
      return (
        <a target="_blank" alt={`${bibNumber}`} href={src}>
          <Button
            type="primary"
            shape="round"
            size="large"
            className="btn-affect-sub btn-inside-finisherwall btn-mobile-right"
            style={{
              marginRight: "20px",
            }}
          >
            <span style={{ fontSize: "14px" }}>{t("finisherwall.ecer")}</span>
          </Button>{" "}
        </a>
      );
    }
    return (
      <Button
        type="primary"
        shape="round"
        size="large"
        className="btn-affect-sub btn-inside-finisherwall btn-mobile-right"
        style={{
          marginRight: "20px",
        }}
        href={`https://ecer.thai.run/${slug}/${number}`}
      >
        <span style={{ fontSize: "14px" }}>{t("finisherwall.ecer")}</span>
      </Button>
    );
  };
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Header />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "100px 0px 0px 0px",
              maxWidth: "1250px",
              backgroundColor: "#FFF",
              minHeight: "100vh",
            }}
            className="bib-tranfer"
          >
            <div className="head-page-user left-page-user">
              <div
                style={{
                  marginTop: "-50px",
                  padding: "5px 10px 5px 10px",
                  backgroundColor: "#eeeeee",
                }}
              >
                <h3
                  style={{ display: "inline" }}
                  className="font-bib-user-bib"
                >{`${bibNumber} `}</h3>
                <h2
                  style={{ display: "inline" }}
                  className="font-bib-user"
                >{`${firstName} ${lastName}`}</h2>
              </div>
            </div>
            <div
              className="appWrapper"
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "inherit",
                height: "100%",
              }}
            >
              <div
                className="btn-group-finisherwall btn-group-finisherwall"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {calculate >= 100 &&
                  !noEcer &&
                  _get(races, "length") === 0 &&
                  ecerLinkHtml({ number, race, slug })}
                <Button
                  type="default"
                  href={`/${event.slug}/submit/${number}`}
                  shape="round"
                  className="btn-affect-cumulative btn-inside-finisherwall"
                  style={{
                    height: "40px",
                    padding: "5px 40px",
                  }}
                  size="small"
                  onclick="yourFunction('1')"
                >
                  <span style={{ fontSize: "14px" }}>
                    {t("finisherwall.resultSubmit")}
                  </span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  shape="round"
                  className="btn-cancel btn-inside-finisherwall btn-mobile-finisherwall"
                  onClick={() => (window.location.href = `/${slug}/`)}
                  style={{
                    height: "40px",
                    padding: "5px 40px",
                    borderRadius: "20px",
                    marginLeft: "20px",
                  }}
                >
                  {t("submit.btnFinisherwall")}
                </Button>
                <Button
                  variant="contained"
                  color="danger"
                  shape="round"
                  className="btn-cancel-remove btn-inside-finisherwall btn-mobile-finisherwall"
                  onClick={() => (window.location.href = `${process.env.REACT_APP_BASE_URI}/reg/${registrationId}?removeRunsRaceId=${raceId}&ticketItemId=${ticketItemId}`)}
                  style={{
                    height: "40px",
                    padding: "5px 20px",
                    borderRadius: "20px",
                    marginLeft: "20px",
                  }}
                >
                  {t("submit.btnRemoveResult")}
                </Button>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "50px 0px 50px 0px",
                }}
              >
                {_get(races, "length") > 1 && (
                  <RaceProgressBars
                    {...{
                      noEcer,
                      calculate,
                      dupDistance,
                      bibNumber,
                      sumDistance,
                      slug,
                      distanceUnit,
                      distanceMode,
                      t,
                      races,
                      locale,
                      number,
                      runItems,
                      isAutoApproved,
                      race: _get(ticketItemOne, "ticketType.race"),
                      ecerHtml,
                      rankMode
                    }}
                  />
                )}
                {_get(races, "length") === 0 ? (
                  <React.Fragment>
                    {calculate >= 100 ? (
                      <React.Fragment>
                        <h4 className="success">Congratulation!</h4>
                        {!noEcer && sumDistance >= dupDistance
                          ? ecerHtml({ number, race })
                          : ""}
                        {distanceMode === "cumulative" ||
                        distanceMode === "recent" ? (
                          <React.Fragment>
                            <div style={{ marginTop: "35px", display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                variant="h4"
                                style={{ display: "inline-block" }}
                              >
                                {sumDistance.toFixed(2)} {distanceUnit}
                              </Typography>
                              {!["skechersfriendshipwalk2021"].includes(
                                event.slug
                              ) && (
                                <Typography
                                  style={{
                                    display: "inline-block",
                                    paddingLeft: "5px",
                                  }}
                                >{` ${t(
                                  "finisherwall.ofall"
                                )} ${dupDistance} ${distanceUnit}`}</Typography>
                              )}
                              {!["skechersfriendshipwalk2021"].includes(
                                event.slug
                              ) && (
                                <div
                                  style={{ width: "300px", maxWidth: "100%" }}
                                >
                                  <Progress
                                    percent={calculate.toFixed(2)}
                                    strokeColor={"#4BB543"}
                                    style={{ color: "#4BB543" }}
                                  />
                                </div>
                              )}
                              {rankMode === 'utpscore' && <React.Fragment>
                                <Typography
                                  variant="h6"
                                  style={{ display: "inline-block" }}
                                >
                                  accumulate Mileage: {sumDistance.toFixed(2)} {distanceUnit}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  style={{ display: "inline-block" }}
                                >
                                  accumulate Point: {sumRunningScore} Points
                                </Typography>
                              </React.Fragment>
                              }
                            </div>
                          </React.Fragment>
                        ) : (
                          <Typography>
                            {sumDistance.toFixed(2)} {distanceUnit}
                          </Typography>
                        )}
                      </React.Fragment>
                    ) : (
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h4 className="progress">PROGRESS</h4>
                        {distanceMode === "cumulative" ||
                        distanceMode === "recent" ? (
                          <React.Fragment>
                            <div>
                              <Typography
                                variant="h4"
                                style={{ display: "inline-block" }}
                              >
                                {sumDistance.toFixed(2)} {distanceUnit}
                              </Typography>
                              {!["skechersfriendshipwalk2021"].includes(
                                event.slug
                              ) && (
                                <Typography
                                  style={{
                                    display: "inline-block",
                                    paddingLeft: "5px",
                                  }}
                                >{` ${t(
                                  "finisherwall.ofall"
                                )} ${dupDistance} ${distanceUnit}`}</Typography>
                              )}
                            </div>
                            {!["skechersfriendshipwalk2021"].includes(
                              event.slug
                            ) && (
                              <div style={{ width: "300px", maxWidth: "100%" }}>
                                <Progress
                                  percent={calculate.toFixed(2)}
                                  strokeColor={"#f9a01b"}
                                  style={{ color: "#f9a01b" }}
                                />
                              </div>
                              
                            )}
                            {rankMode === 'utpscore' && <React.Fragment>
                                <Typography
                                  variant="h6"
                                  style={{ display: "inline-block" }}
                                >
                                  accumulate Mileage: {sumDistance.toFixed(2)} {distanceUnit}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  style={{ display: "inline-block" }}
                                >
                                  accumulate Point: {sumRunningScore} Points
                                </Typography>
                              </React.Fragment>
                              }
                          </React.Fragment>
                        ) : (
                          <Typography>
                            {sumDistance.toFixed(2)} {distanceUnit}
                          </Typography>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
              {isAutoApproved && slug !== 'vr1000kay6' ? (
                <div className="imageGallery">
                  {runItemsFilter.length !== 0 ? (
                    runItems.map((run, index) => {
                      
                      const distance = run.distance;
                      const bibnumber = run.bibNumber;
                      const pace = _get(run, 'pace', 0);
                      const elevationGain = run.elevationGain
                      const runnerThumbnail = _get(
                        run,
                        "runnerImage.thumbnail"
                      );
                      const activityThumbnail = _get(
                        run,
                        "activityImage.thumbnail"
                      );
                      const thumbnail = runnerThumbnail || activityThumbnail;
                      return thumbnail ? (
                        <div
                          className="imageItem item"
                          key={index}
                          onClick={handleRunClick(run)}
                          style={{
                            backgroundImage: `url(${_get(thumbnail, "url")})`,
                          }}
                        >
                          <div className="item-overlay top">
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <p style={{fontSize: '14px'}}>
                                BiB: {bibnumber}
                              </p>
                              <p style={{fontSize: '14px'}}>
                                {eventId === '6448d11c1aa51339842173a1'? 'ระยะกิจกรรม' :'ระยะทาง:'} {distance} {_get(event, ["races", 0, "distanceUnit"], 'Km.')}
                              </p>
                              <p style={{fontSize: '14px'}}>
                               {eventId !== '6448d11c1aa51339842173a1' && `pace: ${pace? pace: 0}`}
                              </p>
                              <p style={{fontSize: '12px'}}>
                                {eventId !== '6448d11c1aa51339842173a1' && `elevation gain: ${elevationGain? elevationGain: 0}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div key={index} style={{ display: "none" }} />
                      );
                    })
                  ) : (
                    <div style={{ divor: "#fff", marginBottom: "30px" }}>
                      No data
                    </div>
                  )}
                </div>
              ) : (
                <React.Fragment>
                  {runItemsWaitting.length !== 0 && (
                    <div>
                      <h2 style={{ fontWeight: 200, textAlign: "start" }}>
                        รอตรวจสอบ
                      </h2>
                      <Divider />
                      <List
                        grid={{
                          gutter: 16,
                          xs: 3,
                          sm: 4,
                          md: 4,
                          lg: 4,
                        }}
                        dataSource={runItemsWaitting}
                        renderItem={(run) => {
                          const distance = run.distance;
                          const bibnumber = run.bibNumber;
                          const runnerThumbnail = _get(
                            run,
                            "runnerImage.thumbnail"
                          );
                          const activityThumbnail = _get(
                            run,
                            "activityImage.thumbnail"
                          );
                          const thumbnail =
                            runnerThumbnail || activityThumbnail;

                          return (
                            <List.Item>
                              <Card
                                bordered={false}
                                style={{ width: "112px", height: "172px" }}
                                cover={
                                  <div className="container">
                                    <img
                                      alt=""
                                      style={{
                                        width: "112px",
                                        height: "172px",
                                        objectFit: "cover",
                                      }}
                                      className="image"
                                      src={`${_get(thumbnail, "url")}`}
                                    />
                                    <div className="middle">
                                      <p className="font-item">
                                        BiB: {bibnumber}{" "}
                                      </p>
                                      <p className="font-item-distance">
                                        ระยะทาง: {distance} Km.
                                      </p>
                                    </div>
                                  </div>
                                }
                              ></Card>
                            </List.Item>
                          );
                        }}
                      />{" "}
                    </div>
                  )}

                  {runItemsReject.length !== 0 && (
                    <div>
                      {" "}
                      <h2
                        style={{
                          fontWeight: 200,
                          textAlign: "start",
                          paddingTop: "50px",
                        }}
                      >
                        ไม่อนุมัติ
                      </h2>
                      <Divider />
                      <List
                        grid={{
                          gutter: 16,
                          xs: 3,
                          sm: 4,
                          md: 4,
                          lg: 4,
                        }}
                        dataSource={runItemsReject}
                        renderItem={(run) => {
                          const distance = run.distance;
                          const bibnumber = run.bibNumber;
                          const runnerThumbnail = _get(
                            run,
                            "runnerImage.thumbnail"
                          );
                          const activityThumbnail = _get(
                            run,
                            "activityImage.thumbnail"
                          );
                          const thumbnail =
                            runnerThumbnail || activityThumbnail;

                          return (
                            <List.Item>
                              <Card
                                bordered={false}
                                style={{ width: "112px" }}
                                cover={
                                  <div className="container">
                                    <img
                                      alt=""
                                      style={{
                                        width: "112px",
                                        height: "172px",
                                        objectFit: "cover",
                                      }}
                                      className="image"
                                      src={`${_get(thumbnail, "url")}`}
                                    />
                                    <div className="middle">
                                      <p className="font-item">
                                        BiB: {bibnumber}{" "}
                                      </p>
                                      <p className="font-item-distance">
                                        ระยะทาง: {distance} Km.
                                      </p>
                                    </div>
                                  </div>
                                }
                              >
                                {run.status === "rejected" ? (
                                  <Meta description={`${run.remarks}`} />
                                ) : (
                                  ""
                                )}
                              </Card>
                            </List.Item>
                          );
                        }}
                      />{" "}
                    </div>
                  )}

                  {runItemsApproved.length !== 0 && (
                    <div>
                      {" "}
                      <h2
                        style={{
                          fontWeight: 200,
                          textAlign: "start",
                          paddingTop: "40px",
                        }}
                      >
                        ตรวจสอบแล้ว
                      </h2>
                      <Divider />
                      <List
                        grid={{
                          gutter: 16,
                          xs: 3,
                          sm: 4,
                          md: 4,
                          lg: 4,
                        }}
                        dataSource={runItemsApproved}
                        renderItem={(run) => {
                          const distance = run.distance;
                          const bibnumber = run.bibNumber;
                          const runnerThumbnail = _get(
                            run,
                            "runnerImage.thumbnail"
                          );
                          const activityThumbnail = _get(
                            run,
                            "activityImage.thumbnail"
                          );
                          const thumbnail =
                            runnerThumbnail || activityThumbnail;

                          return (
                            <List.Item>
                              <Card
                                bordered={false}
                                style={{ width: "112px", height: "172px" }}
                                cover={
                                  <div className="container">
                                    <img
                                      alt=""
                                      style={{
                                        width: "112px",
                                        height: "172px",
                                        objectFit: "cover",
                                      }}
                                      className="image"
                                      src={`${_get(thumbnail, "url")}`}
                                    />
                                    <div className="middle">
                                      <p className="font-item">
                                        BiB: {bibnumber}{" "}
                                      </p>
                                      <p className="font-item-distance">
                                        ระยะทาง: {distance} Km.
                                      </p>
                                    </div>
                                  </div>
                                }
                              ></Card>
                            </List.Item>
                          );
                        }}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-user">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default compose(withTracker, withTranslation())(finisherwallUser);
