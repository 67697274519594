import React from 'react'
import { Col, Row, Divider } from 'antd'
import Facebook from './img/ic_facebook.svg'
import Youtube from './img/ic_youtube.svg'
import Instagram from './img/ic_instagram.svg'

import { 
  FooterContainerV3, 
} from './FooterstyledComponents';


const Footer = () => {
  return (
    <div style={{ display: 'flex',textAlign: 'center',justifyContent: 'center',alignItems:'ceter', width: '100%', color: '#fff' }}>
      <FooterContainerV3>
        <Divider style={{width: 'calc(100% + 45px', marginLeft: '-25px', marginTop: '-20px'}} />
        
            <Row type="flex" justify="space-between">
              <Col xs={0} md={5} style={{textAlign: 'left'}}>
              <p>
              Copyright ©  2020
              </p>
              </Col>
              <Col xs={24} md={0} style={{textAlign: 'center'}}>
              <p>
              Copyright ©  2020
              </p>
              </Col>
              <Col xs={24} md={0} style={{textAlign: 'center'}}>
              <p>
              Thaidotrun Co., Ltd.
              </p>
              </Col>
              <Col xs={24} md={5} style={{textAlign: 'center'}}>
                <a href='https://www.facebook.com/thaidotrun/?epa=SEARCH_BOX' target="_blank" alt="Facebook" rel="noopener noreferrer" >
                  <img src={Facebook} alt="Facebook" style={{ height: '20px', marginRight: '20px' }}/>
                </a>
                <a href='https://www.instagram.com/thai.run/?hl=th' target="_blank" alt="Instagram" rel="noopener noreferrer" >
                  <img src={Instagram} alt="Instagram" style={{ height: '20px', marginRight: '20px' }}/>
                </a>
                <a href='https://www.youtube.com/channel/UCHVnTje5HIUcmswGyqqHTwg' target="_blank" alt="Youtube" rel="noopener noreferrer" >
                  <img src={Youtube} alt="Youtube" style={{ height: '20px' }}/>
                </a>
              </Col>
              <Col xs={0} md={5} style={{textAlign: 'right'}}>
              <p>
              Thaidotrun Co., Ltd.
              </p>
              </Col>
            </Row>
      </FooterContainerV3>
    </div>
     
  )
}

export default Footer