import React, { Component } from "react";
import { Button, Col } from "antd";
import { TotalEvent, Eventbutton } from "../../styled-components/index.js";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import moment from "moment";
import _get from "lodash/get";
import "./css/Home.css";

class NotOpen extends Component {
  render() {
    const { raceImgMap, closedData } = this.props;
    return (
      <div id="closing">
        <div className="textSectionHeader">
          <span className="textSectionHeader" style={{ fontWeight: "bold" }}>
            Past
          </span>{" "}
          events
        </div>
        {closedData.map((data, index) => {
          let timeDay = "00";
          let timeDayEnd = "00";
          let timeMonth = "00";
          let timeMonthEnd = "00";
          let thumbnailUrl = "";
          let title = _get(data,"name.en","");
          let detail = <div></div>;
          let timeStart = "";
          let timeEnd = "";
          let timeOpen = "";
          let finisherwall = "";
          if (data.type === "races_event") {
            timeDay = moment(data.startTime).format("DD");
            timeDayEnd = moment(data.settings.virtualRun.period.end).format(
              "DD"
            );
            timeMonth = moment(data.startTime)
              .locale(this.props.i18n.language)
              .format("MMMM");
            timeMonthEnd = moment(data.settings.virtualRun.period.end)
              .locale(this.props.i18n.language)
              .format("MMMM");
            thumbnailUrl = raceImgMap[data.slug];
            title = _get(data, "name.en");
            timeStart = moment(data.settings.virtualRun.period.start).format(
              "YYYY-MM-DD HH:mm"
            );
            timeEnd = moment(data.settings.virtualRun.period.end).format(
              "YYYY-MM-DD HH:mm"
            );
            timeOpen = moment().isBetween(timeStart, timeEnd);
            detail = (
              <TotalEvent>
                <div className="TEvent">
                  <Eventbutton>
                    <Link to={`/~${data.slug}`}>
                      <Button className="btn-table">FINISHERWALL</Button>
                    </Link>

                    {timeOpen ? (
                      <div>
                        <Link
                          to={{
                            pathname: `/~${data.slug}/submit`,
                            state: { data },
                          }}
                        >
                          <Button className="btn-event">ส่งผลการวิ่ง</Button>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Eventbutton>
                </div>
              </TotalEvent>
            );
          } else if (data.type === "vrEvents_event") {
            if (data.published !== false && data.slug !== "Daretorun") {
              timeDay = moment(data.settings.virtualRun.period.start).format(
                "DD"
              );
              timeDayEnd = moment(data.settings.virtualRun.period.end).format(
                "DD"
              );
              timeMonth = moment(data.settings.virtualRun.period.start)
                .locale(this.props.i18n.language)
                .format("MMMM");
              timeMonthEnd = moment(data.settings.virtualRun.period.end)
                .locale(this.props.i18n.language)
                .format("MMMM");
              thumbnailUrl = data.thumbnailUrl;
              title = _get(data, "name.en");
              timeStart = moment(data.settings.virtualRun.period.start).format(
                "YYYY-MM-DD HH:mm"
              );
              timeEnd = moment(data.settings.virtualRun.period.end).format(
                "YYYY-MM-DD HH:mm"
              );
              timeOpen = moment().isBetween(timeStart, timeEnd);
              finisherwall = data.settings.virtualRun.finisherwall;
              detail = (
                <TotalEvent>
                  <div className="TEvent">
                    <Eventbutton>
                      {finisherwall ? (
                        <Link to={data.slug}>
                          <Button className="btn-table">FINISHERWALL</Button>
                        </Link>
                      ) : (
                        ""
                      )}
                      {timeOpen ? (
                        <div>
                          <Link
                            to={{
                              pathname: `/${data.slug}/submit`,
                              state: { data },
                            }}
                          >
                            <Button className="btn-event">ส่งผลการวิ่ง</Button>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </Eventbutton>
                  </div>
                </TotalEvent>
              );
            }
          } else {
            timeDay = moment(data.startDate).format("DD");
            timeDayEnd = moment(data.endDate).format("DD");
            timeMonth = moment(data.startDate).format("MMMM");
            timeMonthEnd = moment(data.endDate).format("MMMM");
            timeStart = moment(data.startDate).format("YYYY-MM-DD HH:mm");
            timeEnd = moment(data.endDate).format("YYYY-MM-DD HH:mm");
            timeOpen = moment().isBetween(timeStart, timeEnd);
            thumbnailUrl = data.thumbnailUrl;
            title = _get(data,"name.en","");
            detail = (
              <TotalEvent>
                <div className="TEvent">
                  <Eventbutton>
                    <Link to={`/~~${data.slug}`}>
                      <Button className="btn-table">FINISHERWALL</Button>
                    </Link>

                    {timeOpen ? (
                      <div>
                        <Link to={`/~~results/${data._id}`}>
                          <Button className="btn-event">ส่งผลการวิ่ง</Button>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Eventbutton>
                </div>
              </TotalEvent>
            );
          }
          detail = null;
          return thumbnailUrl ? (
            <Link key={index} to={`/${data.slug}`}>
              <Col className="event-col finish">
                <div className="eventCard">
                  <div className="item">
                    <LazyLoad height={200} offsetBottom={100}>
                      <img className="eventCardImg" src={thumbnailUrl} alt="" />
                    </LazyLoad>
                    <div className="item-overlay top">{title}</div>
                    <div
                      style={{ display: "none" }}
                      className="item-overlay top"
                    >
                      {timeDay} {timeMonth} ~ {timeDayEnd} {timeMonthEnd}
                    </div>
                  </div>
                </div>
                {detail}
              </Col>
            </Link>
          ) : null;
        })}
      </div>
    );
  }
}
export default NotOpen;
