import React, { Component, Fragment } from 'react';
import { Row, Col, Select } from 'antd';
import { Link as Linkdom } from 'react-router-dom'
import { Link } from 'react-scroll'
import { withTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog';
import { HeaderContainer, Navber } from './styledComponents';
import styled from 'styled-components'
import Logo from './img/ThaiRun_2021.png';
import HamburgerMenu from './img/ic_menu.svg';
import Cancel from './img/ic_cross.svg';

import './css/style.css'

const locales = {
  en: '🇺🇸 EN',
  th: '🇹🇭 TH',
}

const StyledSelect = styled(Select)`
   .ant-select-selection {
    border: 1.5px solid #928c8c !important;
    border-radius: 20px;
    box-shadow: none !important;
    background-color: #fff;
    color: #000;
  }
  .ant-select-arrow {
    color: #000;
  }
`

const StyledMobileSelect = styled(Select)`
  .ant-select-selection {
    border: none;
    box-shadow: none !important;
    background-color: none;
    color: #000;
    font-weight: 500;
  }
  .ant-select-arrow {
    color: #000;
    font-weight: 500;
    right: 20px;
  }
`
const { Option } = Select


class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const navbar = this.navstk
      const sticky = navbar && navbar.offsetTop
      if (window.pageYOffset > sticky) {
        navbar && navbar.classList.add("sticky")
      }
      else {
        navbar && navbar.classList.remove("sticky")
      }
    }, 1000)
  }
  render() {
    const { t } = this.props

    const handleLanguageChange = (locale) => {
      this.props.i18n.changeLanguage(locale)
    }
    return (
      <div className="nevber-header" ref={stk => this.navstk = stk}>
        <HeaderContainer>
          <Row>
            <Col xs={8}>
            <a href="/" >
              <img style={{ height:'40px', marginLeft: '10px' }} src={Logo} alt="logo" />
            </a>
            </Col>
            <Col xs={0} lg={10} style={{textAlign: 'center', fontSize: '17px', marginTop: '5px'}}>
            <Linkdom to="/" style={{marginRight: '20px', color: 'black'}}>{t('menu.Home')}</Linkdom>
            {this.props.indexPage ? 
            <Fragment>
            <Link activeClass="active" style={{marginRight: '20px', color: 'black'}} className="test1" to="openning" spy={true} smooth={true} duration={500} >{t('menu.Events')}</Link>
            <Link activeClass="active" style={{ color: 'black'}} className="test1" to="closing" spy={true} smooth={true} duration={500} >{t('menu.PastEvents')}</Link>  
            </Fragment>
            :
            <Fragment>
            <Linkdom to="/?ongoing" style={{marginRight: '20px', color: 'black'}} >{t('menu.Events')}</Linkdom>
            <Linkdom to="/?closing" style={{marginRight: '20px', color: 'black'}}>{t('menu.PastEvents')}</Linkdom>  
            </Fragment>
          }
            <Linkdom to={{pathname: 'https://race.thai.run/eventForm'}} target="_blank" style={{marginLeft: '20px', color: 'black'}}>{t('header.context')}</Linkdom>
            </Col>
            <Col xs={0} lg={6}>
              <StyledSelect
                defaultValue={this.props.i18n.language}
                style={{
                  width: 90,
                  float: 'right'
                }}
                bordered={false}
                onChange={(locale) => handleLanguageChange(locale)}
              >
                {Object.entries(locales).map(([key, label], i) => (
                  <Option key={key} value={key}>
                    {label}
                  </Option>
                ))}
              </StyledSelect>
            </Col>
            <Col xs={8} lg={0}></Col>
            <Col xs={8} lg={0} style={{textAlign: 'right'}}>
            <Navber>
            <a onClick={() => this.setState({modal: true}) }><img alt="hambergerMenu" src={HamburgerMenu} height="30px" style={{marginRight: '10px'}} /></a>
        </Navber> 
            </Col>
          </Row>
        </HeaderContainer>

              <Dialog fullWidth maxWidth="xl" open={this.state.modal} onClose={() => this.setState({modal: false })}>
              <Row type="flex" justify="space-between" style={{marginTop: '20px'}}>
                <Col>
                <img style={{ height:'40px', marginLeft: '10px' }} src={Logo} alt="logo" />
                </Col>
                <Col>
                <a onClick={() => {this.setState({modal: false})}}><img style={{ height:'40px', marginRight: '20px' }} src={Cancel} alt="logo" /></a>
                </Col>
              </Row>
              <Row style={{marginTop: '11vh', marginBottom: '11vh'}} type="flex" justify="center">
              <Col xs={24} style={{textAlign: 'center', fontSize: '17px'}}>
            <Linkdom className="textInModal" onClick={() => this.setState({modal: false})} to="/" style={{marginRight: '20px', color: 'black'}}>{t('menu.Home')}</Linkdom>
            <p style={{marginTop: '30px'}} />
            {this.props.indexPage ? 
            <Fragment>
            <Link onClick={() => this.setState({modal: false})} activeClass="active" style={{marginRight: '20px', color: 'black'}} className="textInModal" to="openning" spy={true} smooth={true} duration={500} >{t('menu.Events')}</Link>
            <p style={{marginTop: '30px'}} />
            <Link onClick={() => this.setState({modal: false})} activeClass="active" style={{ color: 'black'}} className="textInModal" to="closing" spy={true} smooth={true} duration={500} >{t('menu.PastEvents')}</Link>  
            <p style={{marginTop: '30px'}} />
            <Linkdom className="textInModal" to={{pathname: 'https://race.thai.run/eventForm'}} target="_blank" style={{marginRight: '20px', color: 'black'}}>{t('header.context')}</Linkdom>  
            </Fragment>
            :
            <Fragment>
            <Linkdom className="textInModal" to="/?ongoing"style={{marginRight: '20px', color: 'black'}} >{t('menu.Events')}</Linkdom>
            <p style={{marginTop: '30px'}} />
            <Linkdom className="textInModal" to="/?closing" style={{ color: 'black'}}>{t('menu.PastEvents')}</Linkdom> 
            <p style={{marginTop: '30px'}} />
            <Linkdom className="textInModal" to={{pathname: 'https://race.thai.run/eventForm'}} target="_blank" style={{marginRight: '20px', color: 'black'}}>{t('header.context')}</Linkdom>  
            <p style={{marginTop: '30vh'}} /> 
            
            </Fragment>
          }
            
            
            </Col>
            <span style={{ padding: '10px' }}>
              <StyledMobileSelect
                  defaultValue={this.props.i18n.language}
                  style={{
                    width: 90,
                    zIndex:2000
                  }}
                  bordered={false}
                  onChange={(locale) => handleLanguageChange(locale)}
                >
                  {Object.entries(locales).map(([key, label], i) => (
                    <Option key={key} value={key}>
                      {label}
                    </Option>
                  ))}
              </StyledMobileSelect> 
            </span>
              </Row>

              </Dialog>

      </div>
    );
  }
}

export default withTranslation()(Header)
