
const calStepToKm = (step) => {
  let steps = step
  if(typeof step === 'string'){
    steps = step.replace(/,/g, '')
  }
  const km = ( parseInt(steps) * 7 ) / 10000
  return km.toFixed(2)
}

export default calStepToKm