import React from 'react'
import {Route, Switch} from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import Homepage from '../components/pages/homepage'
import event from '../components/pages/finisherwall/event/event'
import RaceRecent from '../components/pages/finisherwall/Race_recent'
import RaceCumulative from '../components/pages/finisherwall/Race_cumulative/new'
import SubmitRecent from '../components/pages/submitrace_recent'
import Submit from '../V3/pages/summit'
import Finisherwalluser from '../components/pages/finisherwalluser/finisherwall'


const RouterApp = () => (
    <Switch>
        <Route exact name="homepage" path="/" component={Homepage} />
        <Route name="Submit" path="/~:slug/submit" component={SubmitRecent} />
        <Route name="Submit" path="/:slug/submit/:bibNumber" component={Submit} />
        <Route name="Submit" path="/:slug/submit" component={Submit} />
        <Route name="User" path="/:slug/:bibNumber" component={Finisherwalluser} />
        <Route name="event" path="/~~:slug" component={event} />
        <Route name="race" path="/~:slug" component={RaceRecent} />
        <Route name="Race_event" path="/:slug" component={RaceCumulative} />
    </Switch>
)

export default withTranslation() (RouterApp)