import React, { Component } from 'react'
import{ Row, Col, Table, Progress, Icon } from 'antd'
import _get from 'lodash/get'

class tableParticipant extends Component {

  render() {
    const { filterGender, firstTotalDistance, isLineLogin } = this.props
    const columns = [
        {
            title: 'ลำดับ',
            dataIndex: 'rank',
            key: 'rank',
        },
        {
          title: 'เลขบิบ',
          dataIndex: 'bibNumber',
          key: 'bibNumber'
        },
        {
          title: 'รายละเอียด',
          key: 'firstName',
          render: (filterGender) => {
            const firstName = _get(filterGender, 'firstName')
            const lastName = _get(filterGender, 'lastName')
            const province = _get(filterGender, 'province')
            const gender = _get(filterGender, 'gender')
            const totalDistance = _get(filterGender, 'totalDistance')
            const distanceUnit = _get(filterGender, 'distanceUnit')
            const distance = _get(filterGender, 'distance')
            const date = _get(filterGender, 'date')
            const progressDistance = totalDistance * 100 / firstTotalDistance
            return <Row>
                <Col>
                    {`${firstName} ${lastName}`} {isLineLogin ? '' :
                      gender === 'male' ? <Icon type="man" style={{ color: 'blue' }}/> :
                    <Icon type="woman" style={{ color: '#fb758c' }}/>}
                </Col>
                <Col>
                    <Progress 
                        percent={parseFloat(progressDistance.toFixed(2))} 
                        style={{ 
                            width: '100%',
                            maxWidth: 300
                         }}
                    />
                </Col>
                <Col>
                  <span>ส่งผลทั้งหมดรวม</span>
                  <span> {parseFloat(totalDistance.toFixed(2)).toLocaleString()}</span>
                  <span> {distanceUnit}</span>
                  <span> จังหวัด {province}</span>
                </Col>
                <Col style={{ color: '#bfbfbf' }}>
                  {`ส่งผลล่าสุด ${parseFloat(distance.toFixed(2)).toLocaleString()} ${distanceUnit}, วันที่ ${date}`}
                </Col>
            </Row>
          },
        }
      ];

    return (    
        <Row style={{ padding: '20px 0px' }}>
            <Col>
                <Table 
                    rowKey={'participantId'}
                    columns={columns} 
                    dataSource={filterGender} 
                    onChange={this.onChange}
                />
            </Col>
        </Row>
    );  
  }
}

export default tableParticipant