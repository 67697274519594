import React, { Component, Fragment } from "react";
import { graphql, compose } from "react-apollo";
import { Form, Row, Col, Spin, Input, Button } from "antd";
import { BoxContainer } from "../../styled-components/index.js";
import _orderBy from "lodash/orderBy";
import _groupBy from "lodash/groupBy";
import moment from "moment";
import "./css/Home.css";
import _get from "lodash/get";
import { Divider } from "antd";
import { Element, scroller } from "react-scroll";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import imgHomeH2 from "../../img/vrNew/img_vr_header.svg";
import iconSearch from "../../img/vrNew/icon/ic_search.svg";
import sctop from "../../img/scroll-top.png";
import Footer from "../../../V3/components/footer";
import Header from "../../../V3/components/header";
import OpenIngPages from "./openIng";
import ClosedPages from "./closed";
import PinnedPages from "./PinnedEvents";
import eventsQuery from "~/graphql/queries/events.gql";
import racesQuery from "~/graphql/queries/races.gql";
import vrEventsQuery from "~/graphql/queries/vrEvents.gql";
import vrEventsPagination from "~/graphql/queries/vrEventsPagination.gql";
import raceClient from "~/apollo-clients/race";
import withTracker from "../../../hocs/withTracker.js";
import styled from "styled-components";

const PERPAGE = 10;

const Search = styled(Input)`
  .ant-input,
  .ant-input-group-addon,
  button {
    border-radius: 20px;
  }
`;

const OpenIngPagesTrans = withTranslation()(OpenIngPages);
const ClosedPagesTrans = withTranslation()(ClosedPages);
const PinnedPagesTrans = withTranslation()(PinnedPages);

const raceImgMap = {
  "run-against-breast-cancer":
    "https://storage.googleapis.com/s.run.phuket.run/events/records/original/5b330ab26d80211ee846c397.jpeg",
  "run-for-mom":
    "https://storage.googleapis.com/s.run.phuket.run/events/records/original/update715300716776845b052823aaadef6eb6ac1c67.jpeg",
  "ladys-run":
    "https://storage.googleapis.com/s.run.phuket.run/events/records/original/update715300714562495b052fecaaadef6eb6ac1c69.jpeg",
  "IAAFRun241Bangkok-VirtualRun":
    "https://storage.googleapis.com/storage.race.thai.run/media/2f253ff7-1afe-4cdd-a481-d60c8c40b03e.jpeg",
};

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: [],
      searchKey: "",
      showPopup: false,
      loadFinish: false,
      dataSlice: 39,
      page: 1
    };
  }
  componentDidMount() {}
  componentWillReceiveProps(nextProps) {
    // const { events } = nextProps.data;
    // const orderedEvents = _orderBy(events, ["startDate"], ["desc"]);
    // const timeStart = orderedEvents.map((event) =>
    //   moment(event.startDate).format("YYYY-MM-DD HH:mm")
    // );
    // const timeEnd = orderedEvents.map((event) =>
    //   moment(event.endDate).format("YYYY-MM-DD HH:mm")
    // );
    // this.setState({
    //   timeStart,
    //   timeEnd,
    // });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.search === "?closing") {
        scroller.scrollTo("closing", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
        this.setState({ loadFinish: true });
      } else if (this.props.location.search === "?ongoing") {
        scroller.scrollTo("openning", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
        this.setState({ loadFinish: true });
      }
    }
    setTimeout(() => {
      if (this.state.loadFinish === false) {
        if (this.props.vrEventsLoading === false) {
          if (this.props.location.search === "?closing") {
            scroller.scrollTo("closing", {
              duration: 800,
              delay: 0,
              smooth: "easeInOutQuart",
            });
            this.setState({ loadFinish: true });
          } else if (this.props.location.search === "?ongoing") {
            scroller.scrollTo("closing", {
              duration: 800,
              delay: 0,
              smooth: "easeInOutQuart",
            });
            this.setState({ loadFinish: true });
          }
        }
      }
    }, 1000);
  }

  render() {
    const { t } = this.props;

    const UA = navigator.userAgent || navigator.vendor || window.opera;

    const isLINE = function(UA) {
      return UA.indexOf("Line/") > -1;
    };

    const isFacebook = function(UA) {
      return UA.indexOf("FBAN") > -1 || UA.indexOf("FBAV/") > -1;
    };

    const isAndroid = function(UA) {
      return UA.indexOf("android") > -1;
    };

    if (isLINE(UA) || isAndroid(UA)) {
    }
    if (isFacebook(UA) || isAndroid(UA)) {
    }

    // if (!this.props.data.events) {
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         height: "100vh",
    //       }}
    //     ></div>
    //   );
    // }
    // if (!this.props.data.events) {
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         height: "100vh",
    //       }}
    //     >
    //       <Spin tip="Loading...events" />
    //     </div>
    //   );
    // }

    if (!this.props.races) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin tip="Loading...races" />
        </div>
      );
    }

    if (!this.props.vrEvents) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin tip="Loading...vrEvents" />
        </div>
      );
    }

    if (!this.props.vrPastEvents) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin tip="Loading...vrPastEvents" />
        </div>
      );
    }
    const vrEvents = this.props.vrEvents.map((e) => ({
      ...e,
      type: "vrEvents_event",
      timestartDate: e.settings.virtualRun.period.start,
      timeendDate: e.settings.virtualRun.period.end,
    }));
    const searchKey = this.state.searchKey.toLowerCase().replace(/\s/g, "");
    const countData = vrEvents.filter((event) => {
      const e = !![
        _get(event, "name.th"),
        _get(event, "name.en"),
        _get(event, "title"),
        _get(event, "event.name.th"),
        _get(event, "event.name.en"),
      ].find((k) => {
        return (
          k &&
          k
            .toLowerCase()
            .replace(/\s/g, "")
            .search(searchKey) !== -1
        );
      });
      return e;
    });
    const now = moment();
    const groupedData = _groupBy(
      countData,
      ({ timestartDate, timeendDate, isPinned }) => {
        if (isPinned === true && now.isBetween(timestartDate, timeendDate)) {
          return "pinned";
        }else{
          return "opening";
      }
    }
    );

    const pinnedEvents = _orderBy(
      groupedData.pinned,
      ["timestartDate"],
      ["desc"]
    );
    const openIngData = _orderBy(
      groupedData.opening,
      ["timestartDate"],
      ["desc"]
    );
    const JobsOpenUpcoming = openIngData
    const closedData = this.props.vrPastEvents.items

    const onLoadMore = () => {
      this.setState({page: this.state.page+1})
      this.props.vrFetchMore({
        variables: {
          perPage: PERPAGE,
          page: this.state.page+1
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return Object.assign({}, {
            vrEvents:{
              ...previousResult.vrEvents,
              items: previousResult.vrEvents.items.concat(fetchMoreResult.vrEvents.items)
            }
          })
        }
      })
    };

    const loadMore =
      closedData.length !== this.props.vrPastEvents.count ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            marginBottom: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button
            style={{
              padding: "3px 15px",
              textDecoration: "none",
              textAlign: "center",
              borderRadius: "21px",
              fontSize: "15px",
            }}
            onClick={onLoadMore}
          >
            {t('submit.loadmore')}
          </Button>
        </div>
      ) : null;
    return (
      <Row>
        <a href="#top">
          <img
            src={sctop}
            className="sctop"
            ref={(sct) => (this.sctop = sct)}
            alt="pic"
          />
        </a>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Header indexPage={true} {...this.props} />
          <div className="head-page-home">
            <Row style={{ width: "90%" }}>
              <Col xs={24} sm={24} lg={8}>
                <p className="text-in-header1">THAIRUN</p>
                <p className="text-in-header2">Virtual Run</p>
                <p style={{ marginTop: "11%" }} />
                <Search
                  className="search-box"
                  id="myInput"
                  placeholder={t("header.search")}
                  title="Type in a name"
                  size="large"
                  onChange={(e) => this.setState({ searchKey: e.target.value })}
                  prefix={
                    <img
                      alt="searchIcon"
                      src={iconSearch}
                      height="20px"
                      style={{ marginLeft: "-3px" }}
                    />
                  }
                  style={{
                    zIndex: "9",
                    padding: "0px 0px",
                    borderRadius: "20px",
                  }}
                />
              </Col>
              <Col xs={24} sm={24} lg={16}>
                <img
                  src={imgHomeH2}
                  alt="imgHomeH1"
                  style={{ width: "100%", zIndex: "9" }}
                />
              </Col>
            </Row>
          </div>
        </div>
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <div
            id="AllEvent"
            style={{ position: "Relative", top: "-90px" }}
          ></div>
          <BoxContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#FAFAFA",
              }}
            >
              <Row className="eventrow">
                {pinnedEvents.length > 0 ? (
                  <Fragment>
                    <PinnedPagesTrans
                      pinnedEvents={pinnedEvents}
                      raceImgMap={raceImgMap}
                    />
                    <Divider />
                  </Fragment>
                ) : null}
                <Element
                  name="openning"
                  style={{ position: "relative", top: "-100px" }}
                />
                {JobsOpenUpcoming.length > 0 ? (
                  <Fragment>
                    <OpenIngPagesTrans
                      JobsOpenUpcoming={JobsOpenUpcoming}
                      raceImgMap={raceImgMap}
                    />
                    <Divider />
                  </Fragment>
                ) : null}
                <Element
                  name="closing"
                  style={{ position: "relative", top: "-100px" }}
                />
                {closedData.length > 0 ? (
                  <Fragment>
                    <ClosedPagesTrans
                      closedData={closedData}
                      raceImgMap={raceImgMap}
                    />
                    {loadMore}
                    <Divider />
                  </Fragment>
                ) : null}
              </Row>
            </div>
          </BoxContainer>
        </Col>
        <Col>
          <Footer />
        </Col>
      </Row>
    );
  }
}
export default compose(
  Form.create(),
  // graphql(eventsQuery),
  graphql(racesQuery, {
    props: ({ data }) =>
      data && {
        racesLoading: data.loading,
        racesError: data.error,
        races: data.races,
      },
    options: {
      client: raceClient,
    },
  }),
  graphql(vrEventsQuery, {
    props: ({ data }) => ({
      vrEventsLoading: data.loading,
      vrEventsError: data.error,
      vrEvents: data.events,
    }),
    options: {
      client: raceClient,
    },
  }),
  graphql(vrEventsPagination, {
    props: ({ data }) => ({
      vrPastEventsLoading: data.loading,
      vrPastEventsError: data.error,
      vrPastEvents: data.vrEvents,
      vrFetchMore: data.fetchMore
    }),
    options: {
      client: raceClient,
      variables: {
        perPage : PERPAGE , 
        page: 1
      }
    },
  }),
  withTracker,
  withRouter,
  withTranslation()
)(Homepage);
