import React, { Component } from "react";
import { Spin, Modal, Button, Input, Menu, Icon, Dropdown } from "antd";
import { Query } from "react-apollo";
import queryString from "query-string";
import _get from "lodash/get";
import moment from "moment";
import "./css/style.css";
import {
  HeaderContainer,
  EventContainer,
  EventRunning,
  RunDonation,
  Tableevent,
} from "../../../styled-components/event.js";
import {
  Runamount,
  Rununit,
  TitleEvent,
  EventBalance,
  ButtonSummitevent,
} from "../../../styled-components/event.js";
import logo from "../../../img/logo.png";
import raceClient from "~/apollo-clients/race";
import raceBySlugQuery from "~/graphql/queries/raceBySlug.gql";
import raceRunsQuery from "~/graphql/queries/raceRuns.gql";
import withTracker from "~/hocs/withTracker";

import Footer from "../../../Footer.js";

const PER_PAGE = 200;
const Search = Input.Search;

class Event extends Component {
  state = {
    isOpen: [],
    runnerRecords: [],
    visible: false,
    page: 1,
    bibSearch: "",
    value: 1000,
    skip: 0,
    buttonSearch: false,
  };
  componentDidMount() {
    this.interval = setInterval(() => {
      const navbar = this.navstk;
      const sticky = navbar && navbar.offsetTop;

      if (window.pageYOffset > sticky) {
        navbar && navbar.classList.add("sticky");
      } else {
        navbar && navbar.classList.remove("sticky");
      }
    }, 1000);
  }

  handlePageChange = (page) => {
    const { pathname, search } = this.props.location;
    const searchQuery = queryString.parse(search);
    searchQuery.p = page;
    const searchString = queryString.stringify(searchQuery);
    this.props.history.push({ pathname, search: searchString });
  };

  handleSearch = (searchBib) => {
    const { pathname, search } = this.props.location;
    const searchQuery = queryString.parse(search);
    searchQuery.s = searchBib;
    searchQuery.p = undefined;
    const searchString = queryString.stringify(searchQuery);
    this.props.history.push({ pathname, search: searchString });
  };

  handleRunClick = ({ runnerImage, activityImage }) => (e) => {
    Modal.warning({
      width: "50vw",
      maskClosable: true,
      bodyStile: { padding: "6px" },
      content: (
        <React.Fragment>
          <div style={{ marginLeft: "-38px" }}>
            {runnerImage && (
              <img
                alt="runner"
                src={_get(runnerImage, "original.url")}
                style={{ maxWidth: "100%" }}
              />
            )}
            {activityImage && (
              <img
                alt="activity"
                src={_get(activityImage, "original.url")}
                style={{ maxWidth: "100%" }}
              />
            )}
          </div>
        </React.Fragment>
      ),
    });
  };

  render() {
    const { match } = this.props;
    const { search } = this.props.location;
    const { p: page } = queryString.parse(search);
    const { s: searchBib } = queryString.parse(search);
    const menu = (
      <Menu
        mode="horizontal"
        theme="dark"
        defaultSelectedKeys={["1"]}
        style={{
          background: "rgba(0, 0, 0, 0.60)",
          borderRadius: "0px 0px 5px 5px",
          fontFamily: "Kanit",
        }}
      >
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href="/">
            หน้าหลัก
          </a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Query
        query={raceBySlugQuery}
        client={raceClient}
        variables={{
          slug: match.params.slug,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Spin tip="Loading..." />
              </div>
            );
          }
          if (error) {
            return <div>Error</div>;
          }
          if (!data.raceBySlug) {
            return <div>Race not found</div>;
          }
          const { raceBySlug: race } = data;
          const timeDay = moment(
            data.raceBySlug.settings.virtualRun.period.start
          ).format("DD MMM YYYY");
          const timeDayEnd = moment(
            data.raceBySlug.settings.virtualRun.period.end
          ).format("DD MMM YYYY");
          const totalRunDistance = parseFloat(
            race.totalRunDistance.toFixed(2)
          ).toLocaleString();
          const totalRunDonation = parseFloat(
            race.totalRunDonation.toFixed(2)
          ).toLocaleString();
          return (
            <React.Fragment>
              <div className="header1" ref={(stk) => (this.navstk = stk)}>
                <HeaderContainer>
                  <div className="logo">
                    <a href="/">
                      <img className="logo-top" src={logo} alt="logo" />
                    </a>
                    <Menu
                      className="nav-test"
                      mode="horizontal"
                      theme="light"
                      style={{
                        lineHeight: "64px",
                        background: "transparent",
                        color: "rgb(200, 200, 200)",
                        marginTop: "15px",
                      }}
                    >
                      <Menu.Item key="1" className="button-nav">
                        <a className="main" href="/">
                          หน้าหลัก
                        </a>
                      </Menu.Item>
                    </Menu>
                  </div>
                </HeaderContainer>
                <div className="nav-test1">
                  <Dropdown
                    overlay={menu}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <Icon type="bars" style={{ fontSize: 30, color: "#fff" }} />
                  </Dropdown>
                </div>
              </div>
              <div>
                <div className="head-page">
                  <h2 className="font-title font-finishrwall">FinisherWall©</h2>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TitleEvent>
                  <h3 className="font-title font-slug">
                    {_get(race, "name.en")}
                  </h3>
                  <h2
                    className="font-donationrun"
                    style={{
                      fontSize: "22px",
                      color: "rgb(141, 139, 139)",
                      textAlign: "center",
                    }}
                  >
                    ส่งผล {timeDay} - {timeDayEnd}
                  </h2>
                </TitleEvent>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <EventContainer>
                  <EventRunning>
                    <RunDonation>
                      <span
                        className="font-donationrun"
                        style={{ textAlign: "center" }}
                      >
                        ระยะทางที่ส่งผลแล้ว
                      </span>
                    </RunDonation>
                    <Runamount>
                      <span className="font-amountrun">{totalRunDistance}</span>
                    </Runamount>
                    <Rununit>
                      <span className="font-bahtrun">กิโลเมตร</span>
                    </Rununit>
                  </EventRunning>
                  {race.settings.virtualRun.donationPerDistance > 0 && (
                    <EventBalance>
                      <RunDonation>
                        <span className="font-donationrun">ยอดเงินบริจาค</span>
                      </RunDonation>
                      <Runamount>
                        <span className="font-amountrun">
                          {totalRunDonation}
                        </span>
                      </Runamount>
                      <Rununit>
                        <span className="font-bahtrun">บาท</span>
                      </Rununit>
                    </EventBalance>
                  )}
                </EventContainer>
              </div>
              {!this.state.buttonSearch && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonSummitevent>
                    <Button
                      type="default"
                      onClick={() => this.setState({ buttonSearch: true })}
                      shape="round"
                      style={{ height: "50px", padding: "0 40px" }}
                      size="large"
                      block
                    >
                      <span style={{ fontSize: "23px" }}>
                        ค้นหาผลที่ส่งแล้ว
                      </span>
                    </Button>
                  </ButtonSummitevent>
                </div>
              )}
              {this.state.buttonSearch && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonSummitevent>
                    <Search
                      id="myInput"
                      defaultValue={searchBib}
                      onSearch={this.handleSearch}
                      placeholder="ค้นหาผลที่ส่งแล้วด้วยบิบ..."
                      title="Type in a name"
                      enterButton="ค้นหาผลที่ส่งแล้ว"
                      size="large"
                    />
                  </ButtonSummitevent>
                </div>
              )}
              {searchBib && (
                <div>
                  <Query
                    query={raceRunsQuery}
                    client={raceClient}
                    variables={{
                      raceId: race._id,
                      bibNumber: queryString.parse(this.props.location.search)
                        .s,
                      perPage: 200,
                      page,
                    }}
                  >
                    {({ data, loading, error, fetchMore }) => {
                      if (loading) {
                        return <Spin tip="Loading..." />;
                      }
                      if (error) {
                        return <div>Error</div>;
                      }
                      if (!data.raceRuns) {
                        return <div>Runs not found</div>;
                      }
                      const { raceRuns } = data;
                      const runItems = _get(raceRuns, "items", []);
                      return (
                        <React.Fragment>
                          <Tableevent>
                            <div className="imageGallery">
                              {runItems.length !== 0 ? (
                                runItems.map((run, index) => {
                                  const distance = run.distance;
                                  const bibnumber = run.bibNumber;
                                  const runnerThumbnail = _get(
                                    run,
                                    "runnerImage.thumbnail"
                                  );
                                  const activityThumbnail = _get(
                                    run,
                                    "activityImage.thumbnail"
                                  );
                                  const thumbnail =
                                    runnerThumbnail || activityThumbnail;
                                  return thumbnail ? (
                                    <div
                                      className="imageItem item"
                                      key={index}
                                      onClick={this.handleRunClick(run)}
                                      style={{
                                        backgroundImage: `url(${_get(
                                          thumbnail,
                                          "url"
                                        )})`,
                                      }}
                                    >
                                      <div className="item-overlay top">
                                        <p className="font-item">
                                          BiB: {bibnumber}{" "}
                                        </p>
                                        <p className="font-item-distance">
                                          ระยะทาง: {distance} Km.
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      key={index}
                                      style={{ display: "none" }}
                                    />
                                  );
                                })
                              ) : (
                                <div
                                  style={{
                                    divor: "#fff",
                                    marginBottom: "30px",
                                  }}
                                >
                                  No data
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "20px",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  fetchMore({
                                    variables: {
                                      raceId: race._id,
                                      perPage: PER_PAGE,
                                      page:
                                        Math.ceil(
                                          raceRuns.items.length / PER_PAGE
                                        ) + 1,
                                    },
                                    updateQuery: (
                                      previousResult,
                                      { fetchMoreResult }
                                    ) => {
                                      return {
                                        raceRuns: {
                                          ...previousResult.raceRuns,
                                          items: previousResult.raceRuns.items.concat(
                                            fetchMoreResult.raceRuns.items
                                          ),
                                        },
                                      };
                                    },
                                  });
                                }}
                              >
                                More ...
                              </Button>
                            </div>
                          </Tableevent>
                        </React.Fragment>
                      );
                    }}
                  </Query>
                </div>
              )}
              <Footer />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withTracker(Event);
