import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { graphql, compose, withApollo } from 'react-apollo'
import{ Row, Col, Select, Input } from 'antd'
import _get from 'lodash/get'
import raceClient from '~/apollo-clients/race'
import totalDistanceParticipantByRaceQuery from '~/graphql/queries/totalDistanceParticipantByRace.gql'
import withTracker from '~/hocs/withTracker.js'
import ProvinceChart from './provinceChart'
import TableParticipant from './tableParticipant'

import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import ReactFC from 'react-fusioncharts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)
const { Search } = Input

class participant extends Component {
    state = {
        gender: '',
        firstTotalDistance: 0,
        searchKey: '',
    }

    handleChangeGender = (value) => {
        this.setState({
            gender: value,
        });
    }

    componentWillReceiveProps(nextProps) {
        const { data, region, province } = nextProps
        const ParticipantByRace = _get(data, 'totalDistanceParticipantByRace')
        const firstdata = ParticipantByRace && ParticipantByRace.length > 0 ? ParticipantByRace[0].totalDistance : 0

        if (region === '' ) {
            this.setState({
                gender: '',
                searchKey: ''
            }); 
        }

        if (province === '' ) {
            this.setState({
                gender: '',
                searchKey: ''
            }); 
        }
        this.setState({
            firstTotalDistance: parseFloat(firstdata.toFixed(2)).toLocaleString()
        })
    }

  render() {
    const { data, raceId, eventId, region, distanceUnit, startDate, endDate, isLineLogin } = this.props
    const ParticipantByRace = _get(data, 'totalDistanceParticipantByRace')
    if (ParticipantByRace && ParticipantByRace.length === 0) {
        return ''
    }
    const searchKey = this.state.searchKey.toLowerCase().replace(/\s/g, '')
    const rank = ParticipantByRace && ParticipantByRace.map((participant, index) => ({ ...participant, rank: index + 1}))
    const searchParticipant = rank && rank.filter(filterGender => {
        const e = !![
          _get(filterGender, 'firstName'), 
          _get(filterGender, 'lastName')
        ].find(
          k => {
            return k && k.toLowerCase().replace(/\s/g, '').search(searchKey) !== -1
          }
        )
        return e
    })

    const filterGender = searchParticipant && searchParticipant.filter((data) => { 
        if (this.state.gender) {
            return data.gender === this.state.gender
        } else {
            return data.gender !== this.state.gender
        }
    })
    
    return (    
        <Row style={{ padding: '20px 0px' }}>
            <Col>
                {region && <ProvinceChart eventId={eventId} raceId={raceId} region={region} distanceUnit={distanceUnit} startDate={startDate} endDate={endDate} />}
            </Col>
            <Col>
                <Row type="flex" justify="end" style={{ padding: '20px 0px' }}>
                    <Col xs={12} lg={5}>
                        <Search 
                            id="myInput" 
                            placeholder="ค้นหาชื่อ-นามสกุล" 
                            value={this.state.searchKey}
                            title="Type in a name" 
                            onChange={ e => this.setState({searchKey: e.target.value})}
                            style={{ 
                                width: '100%', 
                                maxWidth: 200
                            }} 
                        />
                    </Col>
                    <Col xs={12} lg={5}>
                        <Select 
                            placeholder="เพศ" 
                            defaultValue=""
                            value={this.state.gender}
                            style={{ width: 180 }} 
                            onChange={this.handleChangeGender}
                        >
                            <Select.Option key="" value="">
                                All
                            </Select.Option>
                            <Select.Option key="male" value="male">
                                ชาย
                            </Select.Option>
                            <Select.Option key="female" value="female">
                                หญิง
                            </Select.Option>
                        </Select>
                    </Col>
                </Row>
            </Col>
            <Col>
              <TableParticipant isLineLogin={isLineLogin} filterGender={filterGender} firstTotalDistance={this.state.firstTotalDistance} />
            </Col>
        </Row>
    );  
  }
}

export default 
compose(
  withApollo,
  withRouter,
  graphql(totalDistanceParticipantByRaceQuery, {
    options: (props) => ({
      variables: { 
        eventId: props.eventId,
        raceId: props.raceId,
        province: props.province,
        region: props.region,
        startDate: props.startDate,
        endDate: props.endDate
      },
      client : raceClient
    })
  }),
  withTracker,
)(participant);
